import request from '@/utils/request';
import { getSessionItem } from '@/utils/storage';

export const AUTHORIZATIONS = 'AUTHORIZATIONS';
export const CALENDARS = 'CALENDARS';
export const CLEAR_CALENDARS = 'CLEAR_CALENDARS';
export const PHONE_BURNER = 'phone_burner';
export const GOOGLE = 'google';
export const OUTLOOK = 'outlook';
export const CALENDLY = 'calendly';
export const CSG = 'csg';

export const fetchAuthorizations = () => async dispatch => {
  const response = await request({
    path: `/v1/authorizations`
  });
  return dispatch({
    payload: response,
    type: AUTHORIZATIONS
  });
};

export const createAuthorization = body => () =>
  request({
    path: `/v1/authorizations`,
    method: 'POST',
    body
  });

export const updateAuthorization = body => () =>
  request({
    path: `/v1/authorizations/${body.id}`,
    method: 'PUT',
    body
  });

export const clearAuthorizationCalendars = () => async dispatch =>
  dispatch({
    type: CLEAR_CALENDARS
  });

export const deleteAuthorization = id => async dispatch => {
  await request({
    path: `/v1/authorizations/${id}`,
    method: 'DELETE'
  });
  return dispatch({
    type: CLEAR_CALENDARS
  });
};

export const fetchAuthorizationCalendars = id => async dispatch => {
  const response = await request({
    path: `/v1/authorizations/${id}/calendars`
  });
  return dispatch({
    payload: response,
    type: CALENDARS
  });
};

export const syncAuthorization = id => async () =>
  request({
    path: `/v1/authorizations/${id}/sync`,
    method: 'PUT'
  });

export const dialSessionAuthorization = body => async () =>
  request({
    path: `/v1/authorizations/${body.id}/dial_session`,
    method: 'POST',
    body: { ...body, contacts_scope: JSON.parse(getSessionItem('fetchContactsParams')) }
  });

export const updateCalendlyApiKey = calendly_api_key => async () =>
  request({
    path: `/v1/calendly/set_calendly_api_key`,
    method: 'PATCH',
    body: { calendly_api_key }
  });

export const authenticateCsg = body => async () =>
  request({
    path: `/v1/csg/authenticate`,
    method: 'POST',
    body
  });
