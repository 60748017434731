import request from '@/utils/request';

export const SMS_USAGE = 'SMS_USAGE';
export const SMS_SUBSCIPTIONS = 'SMS_SUBSCIPTIONS';
export const SMS_ORDERS = 'SMS_ORDERS';
export const SMS_NUMBERS = 'SMS_NUMBERS';
export const SMS_PLANS = 'SMS_PLANS';
export const SMS_PLAN = 'SMS_PLAN';

export const fetchUsage = () => async dispatch => {
  const response = await request({
    path: `/v1/admin/sms/usage`
  });
  dispatch({
    payload: response,
    type: SMS_USAGE
  });
};

export const fetchSubscriptions = () => async dispatch => {
  const response = await request({
    path: `/v1/admin/sms/subscriptions`
  });
  dispatch({
    payload: response,
    type: SMS_SUBSCIPTIONS
  });
};

export const updateSubscription = body => async () =>
  await request({
    path: `/v1/admin/sms/subscriptions`,
    method: 'POST',
    body
  });

export const activateSubscription = body => async () =>
  await request({
    path: `/v1/admin/sms/active_subscription`,
    method: 'POST',
    body
  });

export const cancelSubscription = body => async () =>
  await request({
    path: `/v1/admin/sms/cancel_subscription`,
    method: 'POST',
    body
  });

export const fetchOrders = () => async dispatch => {
  const response = await request({
    path: `/v1/admin/sms/orders`
  });
  dispatch({
    payload: response,
    type: SMS_ORDERS
  });
};

export const updateOrder = body => async () =>
  await request({
    path: `/v1/admin/sms/orders`,
    method: 'POST',
    body
  });

export const cancelOrder = body => async () =>
  await request({
    path: `/v1/admin/sms/cancel_order`,
    method: 'POST',
    body
  });

export const fetchNumbers = () => async dispatch => {
  const response = await request({
    path: `/v1/admin/sms/numbers`
  });
  dispatch({
    payload: response,
    type: SMS_NUMBERS
  });
};

export const fetchSmsPlans =
  ({ q, order, page, limit, all }) =>
  async dispatch => {
    const response = await request({
      path: `/v1/admin/sms/sms_plans`,
      query: { q, order, page, limit, all }
    });
    dispatch({ payload: response, type: SMS_PLANS });
  };

export const fetchSmsPlan =
  ({ id }) =>
  async dispatch => {
    const response = await request({
      path: `/v1/admin/sms/sms_plans/${id}`
    });
    dispatch({
      payload: response,
      type: SMS_PLAN
    });
  };

export const createSmsPlan = body => async () =>
  await request({
    path: `/v1/admin/sms/sms_plans`,
    method: 'POST',
    body
  });

export const updateSmsPlan = (id, body) => async () =>
  await request({
    path: `/v1/admin/sms/sms_plans/${id}`,
    method: 'PATCH',
    body
  });

export const deleteSmsPlan = id => async () =>
  await request({
    path: `/v1/admin/sms/sms_plans/${id}`,
    method: 'DELETE'
  });
