import { SETTINGS, SETTING } from '@/store/actions/admin/settings.actions';

const initialState = {
  settings: []
};

export default function settings(state = initialState, action) {
  switch (action.type) {
    case SETTINGS:
      return {
        ...state,
        settings: action.payload.data
      };
    default:
      return state;
  }
}
