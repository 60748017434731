import request from '@/utils/request';
import moment from 'moment';

export const EVENTS = 'EVENTS';
export const UPDATE_EVENT = 'UPDATE_EVENT';
export const CONTACT_EVENTS = 'CONTACT_EVENTS';
export const PARTICULAR_EVENTS = 'PARTICULAR_EVENTS';
export const ACTIVE_CALENDAR_RANGE = 'CALENDAR_RANGE';

export const saveCalendarDateRange =
  ({ start_date, end_date }) =>
  async dispatch => {
    const dateRange = {
      start_date: start_date && moment(start_date).format('MM/DD/YYYY'),
      end_date: end_date && moment(end_date).format('MM/DD/YYYY')
    };
    dispatch({
      payload: dateRange,
      type: ACTIVE_CALENDAR_RANGE
    });
  };

export const fetchEvents =
  ({ downline_user_id, start_date, end_date, q } = {}) =>
  async dispatch => {
    const payload = q ? { downline_user_id, start_date, end_date, q } : { downline_user_id, start_date, end_date };
    const response = await request({
      path: `/v1/events`,
      query: payload
    });
    return dispatch({
      payload: response,
      type: EVENTS
    });
  };

export const fetchParticularEvents =
  ({ start_date, end_date, today_events }) =>
  async dispatch => {
    const response = await request({
      path: `/v1/events`,
      query: { start_date, end_date, today_events }
    });
    return dispatch({
      payload: response,
      type: PARTICULAR_EVENTS
    });
  };

export const fetchContactEvents = id => async dispatch => {
  const response = await request({
    path: `/v1/events/contact_events`,
    query: { contact_id: id }
  });
  return dispatch({
    payload: response,
    type: CONTACT_EVENTS
  });
};

export const createEvent = body => async () =>
  request({
    path: `/v1/events`,
    method: 'POST',
    body
  });

export const updateEvent = body => async dispatch => {
  const response = await request({
    path: `/v1/events/${body.id}`,
    method: 'PUT',
    body
  });
  return dispatch({
    payload: response,
    type: UPDATE_EVENT
  });
};

export const deleteEvent = id => async () =>
  request({
    path: `/v1/events/${id}`,
    method: 'DELETE'
  });
