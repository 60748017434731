import { TRAININGS, CARRIERS_LIST } from '@/store/actions/other.actions';

const initialState = {
  trainings: [],
  carriersList: []
};

export default function other(state = initialState, action) {
  switch (action.type) {
    case TRAININGS:
      return {
        ...state,
        trainings: action.payload.data
      };
    case CARRIERS_LIST:
      return {
        ...state,
        carriersList: action.payload.data
      };
    default:
      return state;
  }
}
