import { TRAININGS, TRAINING } from '@/store/actions/admin/trainings.actions';

const initialState = {
  trainings: [],
  training: {}
};

export default function trainings(state = initialState, action) {
  switch (action.type) {
    case TRAININGS:
      return {
        ...state,
        trainings: action.payload.data
      };
    case TRAINING:
      return {
        ...state,
        training: action.payload.data
      };
    default:
      return state;
  }
}
