export const DEVICE_STATUSES = {
  READY: 'READY',
  INBOUND: 'INBOUND',
  CONNECTED: 'CONNECTED',
  DISCONNECTED: 'DISCONNECTED'
};

export const CALL_STATUSES = {
  INACTIVE: 'INACTIVE',
  INITIATED: 'INITIATED',
  RINGING: 'RINGING',
  ANSWERED: 'ANSWERED',
  COMPLETED: 'COMPLETED'
};

// export const DEVICE_STATE = {
//   READY: 'READY',
//   INCOMMING_CALL: 'INCOMMING_CALL',
//   ACTIVE_CALL: 'ACTIVE_CALL'
// };

export const CALL_TYPES = {
  INBOUND: 'INBOUND',
  OUTBOUND: 'OUTBOUND'
};

export const CALL_ERRORS = {
  OK: 'OK',
  NO_DEVICE: 'No valid device ready',
  NO_CALL: 'No valid call ready',
  INVALID_PHONE_NUMBER: 'Invlaid phone number',
  INVALID_CALLER_ID: 'Invalid caller id phone number',
  ALLOCATION: 'Over minutes allocated'
};
