import request from '@/utils/request';

export const TRAININGS = 'TRAININGS';
export const CARRIERS_LIST = 'CARRIERS_LIST';

export const fetchTrainings = query => async dispatch => {
  const response = await request({
    path: `/v1/trainings`,
    query
  });
  dispatch({
    payload: response,
    type: TRAININGS
  });
};

export const fetchCarriers =
  (q, additionalDetails = {}) =>
  async dispatch => {
    const response = await request({
      path: `/v1/carriers`,
      query: { q, ...additionalDetails }
    });
    dispatch({
      payload: response,
      type: CARRIERS_LIST
    });
    return response;
  };
