import React, { Component } from 'react';
import { withErrorBoundary } from '@/utils/errors';
import classNames from 'classnames';
import styles from './DialerPadActive.module.scss';

class DialerPadActive extends Component {
  state = {
    debug: true
  };

  async componentDidMount() {
    this.initializeEvents();
  }

  componentDidUpdate(prevProps) {}

  componentWillUnmount() {}

  // ! Initialize

  async initializeEvents() {
    // addEventListener('keypress', (event) => this.handleKeypress(event))
  }

  // ! Events

  onSendDigit(digit) {
    this.props.onSendDigit(digit);
  }

  // ! Event Handlers

  handleKeypress(event) {
    event.preventDefault();
    if (event.key >= 0 && event.key <= 9) {
      this.onDial(event.key);
    } else if (event.key == 'Enter') {
      this.onCall(event.key);
    }
  }

  /**
   * Handle component level logging when in debug
   * mode.
   * @param {*} func
   * @param {*} type
   * @param {*} message
   */
  handleLogging(func, type, message) {
    // if (this.state.debug) {
    //   console.log(`DAILER-PAD-COMPONENT ${func} (${type}): `, message);
    // }
  }

  render() {
    return (
      <div className={classNames(styles.DialerPadWrapper)}>
        <div className={classNames(styles.DialerPadDisplay)}>{this.props.extention}</div>
        <div className={classNames(styles.DialerPadDigits)}>
          <div className={classNames(styles.DialerPadDigitRow)}>
            <div className={classNames(styles.DialerPadDigit)} onClick={() => this.onSendDigit('1')}>
              1
            </div>
            <div className={classNames(styles.DialerPadDigit)} onClick={() => this.onSendDigit('2')}>
              2
            </div>
            <div className={classNames(styles.DialerPadDigit)} onClick={() => this.onSendDigit('3')}>
              3
            </div>
          </div>
          <div className={classNames(styles.DialerPadDigitRow)}>
            <div className={classNames(styles.DialerPadDigit)} onClick={() => this.onSendDigit('4')}>
              4
            </div>
            <div className={classNames(styles.DialerPadDigit)} onClick={() => this.onSendDigit('5')}>
              5
            </div>
            <div className={classNames(styles.DialerPadDigit)} onClick={() => this.onSendDigit('6')}>
              6
            </div>
          </div>
          <div className={classNames(styles.DialerPadDigitRow)}>
            <div className={classNames(styles.DialerPadDigit)} onClick={() => this.onSendDigit('7')}>
              7
            </div>
            <div className={classNames(styles.DialerPadDigit)} onClick={() => this.onSendDigit('8')}>
              8
            </div>
            <div className={classNames(styles.DialerPadDigit)} onClick={() => this.onSendDigit('9')}>
              9
            </div>
          </div>
          <div className={classNames(styles.DialerPadDigitRow)}>
            <div className={classNames(styles.DialerPadDigit)} onClick={() => this.onSendDigit('#')}>
              #
            </div>
            <div className={classNames(styles.DialerPadDigit)} onClick={() => this.onSendDigit('0')}>
              0
            </div>
            <div className={classNames(styles.DialerPadDigit)} onClick={() => this.onSendDigit('*')}>
              *
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withErrorBoundary(DialerPadActive);
