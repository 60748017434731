import request from '@/utils/request';

export const SETTINGS = 'SETTINGS';

export const fetchSettings = () => async dispatch => {
  const response = await request({
    path: `/v1/admin/settings`
  });
  dispatch({
    payload: response,
    type: SETTINGS
  });
};

export const fetchSetting = id => async () =>
  await request({
    path: `/v1/admin/settings/${id}`
  });

export const updateSetting = data => async () => {
  const formDataBody = new FormData();
  formDataBody.append('value', data.value);
  data.file && formDataBody.append('file', data.file);

  await request({
    path: `/v1/admin/settings/${data.id}`,
    method: 'PATCH',
    formData: true,
    formDataBody
  });
};
