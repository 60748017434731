import { STORE_ITEMS, STORE_ITEM } from '@/store/actions/store.actions';

const initialState = {
  store_items: [],
  store_item: { image: {} }
};

export default function store(state = initialState, action) {
  switch (action.type) {
    case STORE_ITEMS:
      return {
        ...state,
        store_items: action.payload.data
      };
    case STORE_ITEM:
      return {
        ...state,
        store_item: action.payload.data
      };
    default:
      return state;
  }
}
