import React, { Component } from 'react';
import { withErrorBoundary } from '@/utils/errors';
import Router from 'next/router';
import { Device } from '@twilio/voice-sdk';
import { connect } from 'react-redux';
import classNames from 'classnames';

import { setStateAsync } from '@/utils/helpers';
import interact from 'interactjs';
import styles from './Dialer.module.scss';

import {
  fetchDeviceToken,
  fetchActiveCalls,
  fetchHistoricalCalls,
  fetchAssociatedContact,
  toggleDeviceVisibility,
  deviceStartDisclaimerPrompt,
  deviceResumeCallRecording,
  deviceStopCallRecording
} from '@/store/actions/dialer.actions';

import DialerPad from './DialerPad';
import DialerNav from './DialerNav';
import DialerActive from './DialerActive';
import DialerInbound from './DialerInbound';
import DialerHistory from './DialerHistory';
import DialerPadActive from './DialerPadActive';

import { DEVICE_STATUSES, CALL_STATUSES, CALL_TYPES, CALL_ERRORS } from '@/store/models/dialer';
import { standardPhoneFormatter } from '@/utils/dialer';

import { publish, subscribe, unsubscribe } from 'pubsub-js';

const _POSTITION = { x: 0, y: 0 };

class Dialer extends Component {
  state = {
    debug: true,
    route: 'keypad',
    contact: {},
    phoneNumber: '',
    extention: '',
    visible: false,
    deviceState: DEVICE_STATUSES.DISCONNECTED,
    deviceIdentity: {},
    deviceCallerId: '',
    deviceUserId: '',
    deviceUsername: '',
    callList: [],
    callHistory: [],
    callType: null,
    callPhoneNumber: '',
    callContactId: '',
    callUserId: '',
    callStartTime: '',
    callDuration: null,
    isActiveCall: false,
    isInboundCall: false,
    isSpeaker: false,
    isRecording: false,
    isHold: false,
    isHistory: true
  };

  device;
  call;
  callDurationRef;
  setStateAsync = setStateAsync.bind(this);

  async componentDidMount() {
    if (!this.dialerMustBeEnabled) return;

    try {
      setTimeout(async () => {
        await this.props.fetchDeviceToken();
        await this.props.fetchActiveCalls();
        await this.props.fetchHistoricalCalls();
        await this.initializeState();
        await this.initializeInteractivity();
        await this.initializeDevice();
        await this.initializeDeviceEvents();
        await this.initializeSubscriptions();
      }, 500);
    } catch (error) {
      console.error(error);
    }
  }

  get dialerMustBeEnabled() {
    const { currentUser } = this.props;
    if (
      this.props.currentAccount['change_password?'] ||
      (currentUser['two_fa_is_required?'] && !currentUser['has_2fa_enabled?'])
    )
      return false;

    if (!currentUser.profile.dialer_verified_phone_number) return false;

    return (
      (currentUser.dialer_subscription?.active &&
        currentUser.dialer_subscription_type === 'manager' &&
        currentUser.dialer_license?.paid) ||
      (currentUser.dialer_subscription?.active && currentUser.dialer_subscription_type === 'self') ||
      currentUser.profile.dialer_enabled
    );
  }

  componentDidUpdate(prevProps) {
    if (prevProps.contacts.contact != this.props.contacts.contact) {
      this.handleContactUpdate();
    }
  }

  componentWillUnmount() {
    unsubscribe(this.handleSubscriptions);
  }

  // ! Global Initializers

  async initializeState() {
    try {
      this.setState({
        deviceUserId: this.props.currentUser.id,
        deviceUserName: this.props.currentUser.username,
        deviceCallerId: this.props.currentUser.profile.dialer_verified_phone_number
      });
      return true;
    } catch (error) {
      console.error('Unable to initialize dialer', error);
      publish('error', 'Unable to initialize dialer');
    }
  }

  async initializeInteractivity() {
    try {
      interact('.draggable').draggable({
        listeners: {
          start() {},
          move(event) {
            _POSTITION.x += event.dx;
            _POSTITION.y += event.dy;
            event.target.style.transform = `translate(${_POSTITION.x}px, ${_POSTITION.y}px)`;
          }
        }
      });
      return true;
    } catch (error) {
      console.error('Unable to initialize interactivity', error);
      publish('error', 'Unable to initialize interactivity');
    }
  }

  async initializeDevice() {
    try {
      let deviceParams = this.setDeviceParameters();

      if (deviceParams) {
        this.device = new Device(deviceParams.token, deviceParams.params);
        return true;
      }

      return false;
    } catch (error) {
      console.error('Unable to initialize device', error);
      publish('error', 'Unable to initialize device');
    }
  }

  async initializeDeviceEvents() {
    try {
      // Define a variable to track the device state
      let deviceState = 'unregistered';

      // Event listener for the 'registered' event
      this.device.on('registered', () => {
        // Update the device state when it is registered
        deviceState = 'registered';
        this.handleDeviceEvents('registered', null);
      });

      // Check the current device state before attempting to register
      if (deviceState === 'unregistered') {
        // Register the device only if it is in the 'unregistered' state
        this.device.on('ready', deviceReference => this.handleDeviceEvents('ready', deviceReference));
        this.device.on('connect', connectionReference => this.handleDeviceEvents('connect', connectionReference));
        this.device.on('disconnect', connectionReference => this.handleDeviceEvents('disconnect', connectionReference));
        this.device.on('incoming', connectionReference => this.handleDeviceEvents('incoming', connectionReference));
        this.device.on('tokenWillExpire', () => this.handleDeviceEvents('tokenWillExpire', null));
        this.device.on('error', deviceError => this.handleDeviceEvents('error', deviceError));

        // Register the device
        this.device.register();
        return true;
      } else {
        // Handle the case where the device is already registered
        console.log('Device is already registered. Skipping registration.');
        return true;
      }
    } catch (error) {
      console.error('Unable to initialize device events', error);
      publish('error', 'Unable to initialize device events');
    }
  }

  async initializeSubscriptions() {
    subscribe('dialer', (message, data) => this.handleSubscriptions(message, data));
  }

  async initializeOutboundCall() {
    try {
      let isValidQuota = this.checkForValidQuota();
      let isValidDevice = this.checkForValidDevice();
      let isValidNumber = this.checkForValidNumber();
      let callParameters = this.setCallParameters();
      let resetState = this.resetState();
      if (
        isValidQuota == CALL_ERRORS.OK &&
        isValidDevice == CALL_ERRORS.OK &&
        isValidNumber == CALL_ERRORS.OK &&
        callParameters
      ) {
        this.call = await this.device.connect({ params: callParameters });
        return true;
      } else {
        if (isValidQuota != CALL_ERRORS.OK) {
          publish('error', 'Please request additional minutes from your manager.');
          return false;
        }
        publish('error', 'Unable to initialize outbound call');
        return false;
      }
    } catch (error) {
      console.error('Unable to initialize outbound call', error);
      publish('error', 'Unable to initialize outbound call');
    }
  }

  async initializeOutboundCallEvents() {
    try {
      let isValidCall = this.checkForValidCall();

      if (isValidCall == CALL_ERRORS.OK) {
        this.call.on('ringing', event => this.handleCallRingingEvents('ringing', event));
        this.call.on('accept', event => this.handleCallAcceptedEvents('accept', event));
        this.call.on('disconnect', event => this.handleCallDisconnectEvents('disconnect', event));
        this.call.on('cancel', event => this.handleCallCancelEvents('cancel', event));
        return true;
      }

      publish('error', 'Unable to initialize outbound call events');
      return false;
    } catch (error) {
      console.error('Unable to initialize outbound call events', error);
      publish('error', 'Unable to initialize outbound call events');
    }
  }

  async initializeOutboundCallState() {
    try {
      await this.setStateAsync({
        isActiveCall: true,
        callType: CALL_TYPES.OUTBOUND,
        callStartTime: new Date()
      });
      return true;
    } catch (error) {
      throw new Error(error);
    }
  }

  async initializeInboundCall(connectionReference) {
    try {
      const inboundNumber = connectionReference?.parameters?.From;

      if (!inboundNumber) {
        return;
      }
      if (!this.props.dialer.visible) {
        await this.onToggleVisibility();
      }

      this.call = connectionReference;

      await this.setStateAsync({
        phoneNumber: inboundNumber,
        callType: CALL_TYPES.INBOUND,
        isInboundCall: true,
        extention: '',
        isRecording: false,
        isHold: false
      });
      await this.props.fetchAssociatedContact({ phone_number: standardPhoneFormatter(inboundNumber) });

      return true;
    } catch (error) {
      publish('error', 'Unable to initialize inbound call');
    }
  }

  async initializeInboundCallEvents() {
    try {
      let isValidCall = this.checkForValidCall();

      if (isValidCall == CALL_ERRORS.OK) {
        this.call.on('ringing', event => this.handleCallRingingEvents('ringing', event));
        this.call.on('accept', event => this.handleCallAcceptedEvents('accept', event));
        this.call.on('disconnect', event => this.handleCallDisconnectEvents('disconnect', event));
        this.call.on('cancel', event => this.handleCallCancelEvents('cancel', event));
        return true;
      }

      // return false;
    } catch (error) {
      publish('error', 'Unable to initialize inbound call events');
    }
  }

  async initializeCallCounter() {
    this.callDurationRef = setInterval(() => {
      this.setState(prevState => ({ callDuration: prevState.callDuration + 1 }));
    }, 1000);
    return true;
  }

  async clearCallDurationCounter() {
    console.log('clearing call counter ...');
    clearInterval(this.callDurationRef);
    await this.setState({ callDuration: 0 });
  }

  async teardownCall() {
    try {
      this.clearCallDurationCounter();
      if (this.checkForValidCall() == CALL_ERRORS.OK) {
        this.call.disconnect();
        this.call = null;
      }
      await this.setStateAsync({ isActiveCall: false, callType: null, extention: '' });
      return true;
    } catch (error) {
      await this.setStateAsync({ isActiveCall: false, callType: null, extention: '' });
      console.error(error);
      return false;
    }
  }

  // ! Root Functions

  async onToggleVisibility() {
    // this.setState({visible: !this.state.visible});
    await this.props.toggleDeviceVisibility(this.props.dialer.visible);
  }

  async onDragDialer() {}

  // ! Dialer Pad Functions

  onDial(digit) {
    this.setState({ phoneNumber: this.state.phoneNumber + digit });
  }

  onBack() {
    this.setState({ phoneNumber: this.state.phoneNumber.slice(0, this.state.phoneNumber.length - 1) });
  }

  onReset() {
    this.setState({ phoneNumber: '' });
  }

  async onCall() {
    try {
      let isCallInitialized = await this.initializeOutboundCall();
      if (!isCallInitialized) {
        return;
      }
      let isCallEventsInitialized = await this.initializeOutboundCallEvents();
      let isCallStateInitialized = await this.initializeOutboundCallState();
      if (isCallInitialized && isCallEventsInitialized && isCallStateInitialized) {
        await this.initializeCallCounter();
        return true;
      } else {
        return false;
      }
    } catch (error) {
      console.error('Unable to initialize call', error);
      publish('error', 'Unable to initialize call');
    }
  }

  async onSendDigit(digit) {
    await this.setStateAsync({ extention: this.state.extention + digit });
    if (this.call) {
      this.call.sendDigits(digit);
    }
  }

  // ! Dialer Inbound Functions

  async onAnswer() {
    try {
      this.call.accept();
      await this.initializeCallCounter();

      await this.setStateAsync({
        isInboundCall: false,
        isActiveCall: true,
        callStartTime: new Date()
      });

      if (this.props.dialer?.associated_contact && this.props.dialer?.associated_contact.number) {
        return Router.push(`/contacts/${this.props.dialer?.associated_contact.number}`);
      }
    } catch (error) {
      console.error(error);
    }
  }

  async onReject() {
    try {
      this.call.reject();
      await this.setStateAsync({
        isInboundCall: false,
        isActiveCall: false
      });
      // this.deviceEvents('onCallReject', 'trigger', 'run');
      // this.setState({softPhoneIncoming: false});
      // this.state.softPhoneIncomingCall.reject();
    } catch (error) {
      console.error(error);
    }
  }

  // ! Dialer Active Functions

  async onHold() {
    if (this.checkForValidCall() == CALL_ERRORS.OK) {
      this.call.mute(!this.state.isHold);
      await this.setStateAsync({ isHold: !this.state.isHold });
    }
  }

  async onRecord() {
    try {
      let response = {};
      if (this.state.isRecording) {
        let response = await this.props.deviceStopCallRecording({ call_id: this.call.parameters.CallSid });
        if (response && response.data) {
          await this.setStateAsync({ isRecording: false });
        }
      } else {
        let response = await this.props.deviceResumeCallRecording({ call_id: this.call.parameters.CallSid });
        if (response && response.data) {
          await this.setStateAsync({ isRecording: true });
        }
      }
    } catch (error) {
      console.error(error);
    }
  }

  async onTransfer() {}

  async onPrompt() {
    try {
      await this.props.deviceStartDisclaimerPrompt({ call_id: this.call.parameters.CallSid });
    } catch (error) {
      console.error(error);
    }
  }

  async onSpeaker() {}

  async onHangup() {
    try {
      let isCallToreDown = await this.teardownCall();
      // let isCallSateToreDown = await this.tearDownCallState();
      // return isCallToreDown && isCallSateToreDown;
      return isCallToreDown;
    } catch (error) {
      console.error('Unable to hangup', error);
      publish('error', 'Unable to hangup');
    }
  }

  async onShowDialpad() {
    this.onNavigate('dialpad');
  }

  // ! Dialer Nav Functions

  async onNavigate(route) {
    await this.setStateAsync({ route: route });
  }

  // ! Dialer History Functions

  async onRedial(redialNumber) {
    await this.setStateAsync({ phoneNumber: redialNumber });
    this.onCall();
  }

  // ! Global Event Management

  handleDeviceEvents(type, event) {
    // this.handleLogging('handleDeviceEvents', type, event);
    switch (type) {
      case 'registered':
        this.handleDeviceRegistered(event);
        break;
      case 'ready':
        this.handleDeviceReady(event);
        break;
      case 'error':
        this.handleDeviceError(event);
        break;
      case 'connect':
        this.handleDeviceConnect(event);
        break;
      case 'disconnect':
        this.handleDeviceDisonnect(event);
        break;
      case 'tokenWillExpire':
        this.handleTokenExpiration();
        break;
      case 'incoming':
        console.log('Incoming call ... ');
        this.handleDeviceIncoming(event);
        break;
      default:
        break;
    }
  }

  async handleSubscriptions(message, event) {
    try {
      switch (event.type) {
        case 'click2dial':
          console.log('Running click2dial');
          await this.setStateAsync({
            phoneNumber: event.data.phone,
            contact: event.data?.contact,
            callContactId: event.data?.contact?.id
          });
          if (!this.props.dialer.visible) {
            await this.props.toggleDeviceVisibility(this.props.dialer.visible);
          }
          await this.onCall();
          break;

        default:
          break;
      }
    } catch (error) {
      console.error(error);
    }
  }

  // ! Device Events

  async handleDeviceReady(deviceReference) {
    console.log('DIALER EVENT: Device is ready ...', deviceReference);
  }

  async handleDeviceRegistered(deviceReference) {
    console.log('DIALER EVENT: Device is registered ...', deviceReference);
  }

  async handleDeviceError(deviceError) {
    console.error('DIALER EVENT: Device has error ...', deviceError);
  }

  async handleDeviceConnect(connectionReference) {
    console.log('DIALER EVENT: Device is connected ...', connectionReference);
  }

  async handleDeviceDisconnect(connectionReference) {
    console.log('DIALER EVENT: Device is disconnected ...', connectionReference);
  }

  async handleDeviceIncoming(connectionReference) {
    console.log('DIALER EVENT: Device has incomming connection ...', connectionReference);
    await this.initializeInboundCall(connectionReference);
    await this.initializeInboundCallEvents();
  }

  async handleTokenExpiration() {
    await this.props.fetchDeviceToken();
    await this.device.updateToken(this.props.dialer.token);
  }

  // ! Call Events

  handleCallRingingEvents() {}

  handleCallAcceptedEvents() {}

  handleCallDisconnectEvents(connectionReference) {
    console.error('DIALER EVENT: Call has been disconnected ...', connectionReference);
    this.onHangup();
  }

  handleCallCancelEvents(connectionReference) {
    console.log('DIALER EVENT: Call has been cancelled ...', connectionReference);
    this.onReject();
  }

  // ! Inbound Events

  handleAnswerCall() {
    this.onAnswer();
  }

  handleRejectCall() {
    this.onReject();
  }

  async handleContactUpdate() {
    try {
      await this.setStateAsync({ contact: this.props.contacts.contact });

      if (!this.state.isActiveCall && this.state.contact.phone && this.state.contact.phone !== this.state.phoneNumber) {
        this.setState({
          phoneNumber: this.state.contact.phone,
          callContactId: this.state.contact.id
        });
      }

      if (!this.state.isActiveCall && !this.state.contact.phone && this.state.phoneNumber != '') {
        this.setState({
          phoneNumber: '',
          callContactId: null
        });
      }
    } catch (error) {
      publish('error', 'Unable to update number');
    }
  }

  handleLogging(func, type, message) {
    if (this.state.debug) {
      console.log(`DAILER-COMPONENT ${func} (${type}): `, message);
    }
  }

  // ! Parameters

  setDeviceParameters() {
    return {
      token: this.props.dialer.token,
      params: { logLevel: 1, codecPreferences: ['opus', 'pcmu'] }
    };
  }

  setCallParameters() {
    // TODO Remove once API has been updated with new
    // TODO parameter values.

    const { phoneNumber, deviceCallerId, callContactId, deviceUserId } = this.state;

    const parameters = {
      To: `${phoneNumber}`,
      callerId: deviceCallerId,
      deviceUserId,
      callContactId,
      cid:
        this.state.contact?.id && this.state.contact?.id != null && this.state.contact?.id != undefined
          ? this.state.contact?.id.toString()
          : '',
      uid: deviceUserId
    };

    return parameters;
  }

  async resetState() {
    await this.setState({
      isRecording: false,
      isHold: false
    });
  }

  // ! Checks

  checkForValidQuota() {
    if (this.props.currentUser.profile.dialer_enabled) {
      return CALL_ERRORS.OK;
    }

    if (
      this.props.currentUser.dialer_subscription_type === 'manager' &&
      this.props.currentUser.dialer_subscription?.current_usage >= this.props.currentUser.dialer_license?.allocation
    ) {
      return CALL_ERRORS.ALLOCATION;
    }

    if (this.props.currentUser.dialer_subscription_type === 'manager' && !this.props.currentUser.dialer_license.paid) {
      return CALL_ERRORS.ALLOCATION;
    }

    return CALL_ERRORS.OK;
  }

  checkForValidDevice() {
    if (!this.device) {
      return CALL_ERRORS.NO_DEVICE;
    }
    return CALL_ERRORS.OK;
  }

  checkForValidNumber() {
    if (this.state.phoneNumber.length < 9 || this.state.phoneNumber.length > 16) {
      // throw new Error(CALL_ERRORS.INVALID_PHONE_NUMBER);
      return CALL_ERRORS.INVALID_PHONE_NUMBER;
    }
    return CALL_ERRORS.OK;
  }

  checkForValidCall() {
    if (!this.call) {
      return CALL_ERRORS.NO_CALL;
    }
    return CALL_ERRORS.OK;
  }

  // ! Render

  render() {
    if (!this.dialerMustBeEnabled) return;

    let {
      route,
      visible,
      phoneNumber,
      extention,
      callStartTime,
      isInboundCall,
      isActiveCall,
      isRecording,
      isSpeaker,
      isHold,
      isHistory,
      callDuration
    } = this.state;
    return (
      <div className={classNames(styles.DialerWrapper)}>
        {/* SoftPhone */}
        {this.props.dialer.visible && (
          <div className={classNames(styles.DialerPhoneWrapper, 'draggable')}>
            {!isActiveCall && !isInboundCall && route !== 'history' && (
              <DialerPad
                phoneNumber={phoneNumber}
                onDial={digit => this.onDial(digit)}
                onBack={() => this.onBack()}
                onReset={() => this.onReset()}
                onCall={() => this.onCall()}
                handleOutboundCall={() => this.handleOutboundCall()}
              ></DialerPad>
            )}
            {!isActiveCall && !isInboundCall && route === 'history' && (
              <DialerHistory
                callHistory={this.props.dialer.call_history}
                onRedial={number => this.onRedial(number)}
              ></DialerHistory>
            )}
            {!isActiveCall && isInboundCall && (
              <DialerInbound
                phoneNumber={phoneNumber}
                associated_contact={this.props.dialer.associated_contact}
                handleAnswerCall={() => this.handleAnswerCall()}
                handleRejectCall={() => this.handleRejectCall()}
              ></DialerInbound>
            )}
            {isActiveCall && route !== 'dialpad' && (
              <DialerActive
                phoneNumber={phoneNumber}
                callStartTime={callStartTime}
                callDuration={callDuration}
                isSpeaker={isSpeaker}
                isRecording={isRecording}
                isHold={isHold}
                onHold={() => this.onHold()}
                onPrompt={() => this.onPrompt()}
                onTransfer={() => this.onTransfer()}
                onRecord={() => this.onRecord()}
                onSpeaker={() => this.onSpeaker()}
                onHangup={() => this.onHangup()}
                onShowDialpad={() => this.onShowDialpad()}
                handleDisconnectCall={() => this.handleDisconnectCall()}
              ></DialerActive>
            )}

            {isActiveCall && route === 'dialpad' && (
              <DialerPadActive extention={extention} onSendDigit={digit => this.onSendDigit(digit)}></DialerPadActive>
            )}

            {!isInboundCall && (
              <DialerNav
                route={this.state.route}
                isActiveCall={this.state.isActiveCall}
                isInboundCall={this.state.isInboundCall}
                onNavigate={route => this.onNavigate(route)}
              ></DialerNav>
            )}
          </div>
        )}

        {/* Launch Icon */}
        <div className={classNames(styles.DialerLaunchIconWrapper)}>
          <div className={classNames(styles.DialerLaunchIconButton)} onClick={() => this.onToggleVisibility()}>
            <i className="fa-solid fa-phone"></i>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  currentUser: state.currentUser,
  currentAccount: state.currentAccount.info,
  dialer: state.dialer,
  contacts: state.contacts,
  callList: state.callList,
  callHistory: state.callHistory
});

export default connect(mapStateToProps, {
  fetchDeviceToken,
  fetchActiveCalls,
  fetchHistoricalCalls,
  fetchAssociatedContact,
  toggleDeviceVisibility,
  deviceStartDisclaimerPrompt,
  deviceResumeCallRecording,
  deviceStopCallRecording
})(withErrorBoundary(Dialer));
