import React from 'react';
import { withErrorBoundary } from '@/utils/errors';
import classNames from 'classnames';
import styles from './Icon.module.scss';

const Icon = props => {
  const icon = props?.icon;
  const size = props?.size ? props?.size : 20;
  const color = props?.color ? props?.color : '#474747';
  const bg = props?.bg ? props?.bg : 'white';
  const isCircle = props?.circle ? props?.circle : false;
  const isSquare = props?.square ? props?.square : false;
  const fallback = `/static/icons/mad/icos/${icon}.png`;

  const record = () => {
    return (
      <svg
        className={classNames(isCircle ? styles.Circle : '', isSquare ? styles.Square : '')}
        width={size}
        height={size}
        viewBox="0 0 401 401"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="401" height="401" fill="none" />
        <circle cx="200.5" cy="200.5" r="131.5" fill={color} />
        <circle cx="200.5" cy="200.5" r="61.5" fill={bg} />
        <circle cx="201" cy="200" r="26" fill={color} />
        <image src={fallback} xlinkHref={''} />
      </svg>
    );
  };

  const dialpad = () => {
    return (
      <svg
        className={classNames(isCircle ? styles.Circle : '', isSquare ? styles.Square : '')}
        width={size}
        height={size}
        viewBox="0 0 401 401"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="401" height="401" fill="none" />
        <circle cx="108.5" cy="108.5" r="39.5" fill={color} />
        <circle cx="200.5" cy="108.5" r="39.5" fill={color} />
        <circle cx="292.5" cy="108.5" r="39.5" fill={color} />
        <circle cx="108.5" cy="200.5" r="39.5" fill={color} />
        <circle cx="200.5" cy="200.5" r="39.5" fill={color} />
        <circle cx="292.5" cy="200.5" r="39.5" fill={color} />
        <circle cx="108.5" cy="292.5" r="39.5" fill={color} />
        <circle cx="200.5" cy="292.5" r="39.5" fill={color} />
        <circle cx="292.5" cy="292.5" r="39.5" fill={color} />
        <image src={fallback} xlinkHref={''} />
      </svg>
    );
  };

  const mute = () => {
    return (
      <svg
        className={classNames(isCircle ? styles.Circle : '', isSquare ? styles.Square : '')}
        width={size}
        height={size}
        viewBox="0 0 401 401"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="401" height="401" fill="none" />
        <g clipPath="url(#clip0_7_79)">
          <path
            d="M343.2 292.577L271.805 237.4C277.46 226.47 280.75 214.132 280.75 201V179.25C280.75 175.244 277.506 172 273.5 172H266.25C262.244 172 259 175.244 259 179.25V201C259 209.12 257.206 216.769 254.143 223.747L242.112 214.449C243.517 210.189 244.505 205.74 244.505 201.005H224.717L205.957 186.505H244.5V172.005H205.835C203.166 172.005 201 170.382 201 168.38V161.13C201 159.127 203.166 157.505 205.835 157.505H244.5V143.005H205.835C203.166 143.005 201 141.382 201 139.38V132.13C201 130.127 203.166 128.505 205.835 128.505H244.5C244.5 104.48 225.025 85.0045 201 85.0045C176.975 85.0045 157.5 104.48 157.5 128.505V149.058L76.6036 86.527C73.4408 84.0711 68.8824 84.6375 66.4264 87.8003L57.5271 99.2508C55.0711 102.409 55.6375 106.963 58.8003 109.423L325.401 315.473C328.564 317.933 333.118 317.362 335.578 314.2L344.478 302.749C346.929 299.591 346.363 295.037 343.2 292.577V292.577ZM237.25 295.25H211.875V279.943C217.181 279.209 222.342 278.004 227.263 276.282L204.426 258.628C201.385 258.81 198.349 259.023 195.205 258.719C169.898 256.25 150.463 236.684 144.826 212.568L121.25 194.344V197.447C121.25 238.066 150.236 274.275 190.125 279.776V295.25H164.75C160.744 295.25 157.5 298.494 157.5 302.5V309.75C157.5 313.756 160.744 317 164.75 317H237.25C241.256 317 244.5 313.756 244.5 309.75V302.5C244.5 298.494 241.256 295.25 237.25 295.25Z"
            fill={color}
          />
        </g>
        <defs>
          <clipPath id="clip0_7_79">
            <rect width="290" height="290" fill={bg} transform="translate(56 56)" />
          </clipPath>
        </defs>
        <image src={fallback} xlinkHref={''} />
      </svg>
    );
  };

  const unmute = () => {
    return (
      <svg
        className={classNames(isCircle ? styles.Circle : '', isSquare ? styles.Square : '')}
        width={size}
        height={size}
        viewBox="0 0 401 401"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="401" height="401" fill="none" />
        <path
          d="M274.562 170.875H267.156C263.064 170.875 259.75 174.189 259.75 178.281V200.5C259.75 235.124 229.898 262.907 194.58 259.463C163.797 256.459 141.25 228.787 141.25 197.862V178.281C141.25 174.189 137.936 170.875 133.844 170.875H126.438C122.346 170.875 119.031 174.189 119.031 178.281V196.871C119.031 238.364 148.642 275.354 189.391 280.974V296.781H163.469C159.377 296.781 156.062 300.096 156.062 304.188V311.594C156.062 315.686 159.377 319 163.469 319H237.531C241.623 319 244.938 315.686 244.938 311.594V304.188C244.938 300.096 241.623 296.781 237.531 296.781H211.609V281.149C251.284 275.706 281.969 241.651 281.969 200.5V178.281C281.969 174.189 278.654 170.875 274.562 170.875ZM200.5 244.938C225.042 244.938 244.938 225.042 244.938 200.5H205.439C202.713 200.5 200.5 198.843 200.5 196.797V189.391C200.5 187.345 202.713 185.688 205.439 185.688H244.938V170.875H205.439C202.713 170.875 200.5 169.218 200.5 167.172V159.766C200.5 157.72 202.713 156.062 205.439 156.062H244.938V141.25H205.439C202.713 141.25 200.5 139.593 200.5 137.547V130.141C200.5 128.095 202.713 126.438 205.439 126.438H244.938C244.938 101.895 225.042 82 200.5 82C175.958 82 156.062 101.895 156.062 126.438V200.5C156.062 225.042 175.958 244.938 200.5 244.938Z"
          fill={color}
        />
        <image src={fallback} xlinkHref={''} />
      </svg>
    );
  };

  const phoneHangup = () => {
    return (
      <svg
        className={classNames(isCircle ? styles.Circle : '', isSquare ? styles.Square : '')}
        width={size}
        height={size}
        viewBox="0 0 401 401"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="401" height="401" fill="none" />
        <path
          d="M92.4388 242.13L142.379 222.154C144.509 221.297 146.303 219.772 147.491 217.807C148.678 215.842 149.196 213.545 148.965 211.261L145.532 176.864C181.284 164.023 220.391 164.023 256.143 176.864L252.71 211.261C252.475 213.546 252.99 215.845 254.178 217.81C255.367 219.776 257.163 221.301 259.296 222.154L309.236 242.13C311.547 243.038 314.103 243.11 316.461 242.335C318.82 241.56 320.835 239.986 322.158 237.885L347.128 197.933C348.398 195.901 348.941 193.499 348.669 191.119C348.398 188.739 347.327 186.521 345.633 184.827C265.572 104.766 135.915 104.891 55.9793 184.827C54.2836 186.52 53.2122 188.739 52.9401 191.12C52.668 193.501 53.2112 195.904 54.4811 197.936L79.4512 237.888C80.785 239.995 82.8115 241.572 85.1819 242.346C87.5524 243.12 90.1185 243.044 92.4388 242.13V242.13Z"
          fill={color}
        />
        <image src={fallback} xlinkHref={''} />
      </svg>
    );
  };

  return (
    <>
      {icon && icon === 'record' && record()}
      {icon && icon === 'dialpad' && dialpad()}
      {icon && icon === 'mute' && mute()}
      {icon && icon === 'unmute' && unmute()}
      {icon && icon === 'phone-hangup' && phoneHangup()}
    </>
  );
};

export default withErrorBoundary(Icon);
