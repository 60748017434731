export const _DEVICE_STATUS = {
  READY: 'READY',
  CONNECTED: 'CONNECTED',
  DISCONNECTED: 'DISCONNECTED'
};

export const _CALL_STATUS = {
  INACTIVE: 'INACTIVE',
  INITIATED: 'INITIATED',
  RINGING: 'RINGING',
  ANSWERED: 'ANSWERED',
  COMPLETED: 'COMPLETED'
};

export const _CALL_TYPES = {
  INBOUND: 'INBOUND',
  OUTBOUND: 'OUTBOUND'
};

export const _CALL_ERRORS = {
  NO_DEVICE: 'No valid device ready',
  NO_CALL: 'No valid call ready',
  INVALID_PHONE_NUMBER: 'Invlaid phone number',
  INVALID_CALLER_ID: 'Invalid caller id phone number'
};

export function standardPhoneFormatter(phoneNumber) {
  phoneNumber = phoneNumber.replace(/-/g, '');
  phoneNumber = phoneNumber.replace(/\+1/, '');
  return phoneNumber;
}
