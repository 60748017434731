import {
  TRANSFER_ATTEMPTS,
  REVERT_TRANSFER_ATTEMPT,
  CLEAR_REVERT,
  UNLOAD_TRANSFER_ATTEMPT,
  TRANSFER_ATTEMPT
} from '@/store/actions/admin/transfers.actions';
import { OPTIMISTIC_UPDATE_TRANSFER_ATTEMPT } from '@/store/actions/contacts.actions';

const initialState = {
  transfer_attempts: [],
  meta: {
    current_page: 1,
    limit: 15,
    total_count: 0,
    total_pages: 0
  },
  transfer_attempt: {
    data: null,
    meta: {
      current_page: 1,
      limit: 50,
      total_count: 0,
      total_pages: 0
    }
  },
  revert_in_progress: null
};

export default function transfers(state = initialState, action) {
  switch (action.type) {
    case TRANSFER_ATTEMPT:
      return {
        ...state,
        transfer_attempt: { ...state.transfer_attempt, data: action.payload.data, meta: action.payload.meta }
      };
    case TRANSFER_ATTEMPTS:
      return {
        ...state,
        transfer_attempts: action.payload.data,
        meta: {
          current_page: action.payload.meta.current_page,
          limit: action.payload.meta.limit,
          total_count: action.payload.meta.total_count,
          total_pages: action.payload.meta.total_pages
        }
      };
    case REVERT_TRANSFER_ATTEMPT:
      return {
        ...state,
        revert_in_progress: action.payload
      };
    case CLEAR_REVERT:
      return {
        ...state,
        revert_in_progress: null
      };
    case UNLOAD_TRANSFER_ATTEMPT:
      return {
        ...state,
        transfer_attempt: {
          data: null,
          meta: {
            current_page: 1,
            limit: 50,
            total_count: 0,
            total_pages: 0
          }
        }
      };
    case OPTIMISTIC_UPDATE_TRANSFER_ATTEMPT:
      return {
        ...state,
        transfer_attempts: state.transfer_attempts.map(transfer => {
          if (transfer.id === action.payload.id) {
            if (action.payload.revert) {
              return { ...transfer, reverted: true, updated_at: action.payload.updated_at };
            }
            return { ...transfer, status: 'complete', updated_at: action.payload.updated_at };
          }
          return transfer;
        }),
        transfer_attempt: {
          ...state.transfer_attempt,
          data: state.transfer_attempt.data
            ? { ...state.transfer_attempt.data, ...action.payload, reverted: action.payload.revert }
            : state.transfer_attempt.data
        }
      };
    default:
      return state;
  }
}

export const selectTransferAttempts = state => state?.admin?.transfers?.transfer_attempts;
export const selectTransferAttemptsMeta = state => state?.admin?.transfers?.meta;
export const selectTotalTransfersCount = state => state?.admin?.transfers?.meta?.total_count ?? 0;
export const selectTransferAttempt = state => state?.admin?.transfers?.transfer_attempt?.data;
export const selectTransferLogsMeta = state => state?.admin?.transfers?.transfer_attempt?.meta;
export const selectRevertInProgress = state => state?.admin.transfers?.revert_in_progress;
