import { REPORTS, REPORTS_TYPES, REPORT, SAVE_REPORT_DATA, SET_SELECTED_REPORT } from '@/store/actions/report.actions';

const initialState = {
  reports: [],
  reports_meta: {},
  types: [],
  report: {},
  report_data: {},
  selectedReport: null
};

export default function report(state = initialState, action) {
  switch (action.type) {
    case REPORTS:
      return {
        ...state,
        reports: action.payload.data,
        reports_meta: action.payload.meta
      };
    case REPORTS_TYPES:
      return {
        ...state,
        types: action.payload.data
      };
    case REPORT:
      return {
        ...state,
        report: action.payload.data
      };
    case SAVE_REPORT_DATA:
      return {
        ...state,
        report_data: action.payload
      };
    case SET_SELECTED_REPORT:
      return {
        ...state,
        selectedReport: action.payload
      };
    default:
      return state;
  }
}
