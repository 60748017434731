import request from '@/utils/request';

export const WEBPAGE = 'WEBPAGE';
export const PUBLIC_ERROR = 'PUBLIC_ERROR';

export const health = () => async () => {
  await request({ path: `/v1/health` });
};

export const fetchWebpageInfo = ({ domain }) => {
  return async dispatch => {
    try {
      const response = await request({
        path: `/v1/users/${domain}/webpage`
      });

      return dispatch({
        payload: response,
        type: WEBPAGE
      });
    } catch (e) {
      return dispatch({
        type: PUBLIC_ERROR
      });
    }
  };
};

export const webpageContactRequest =
  ({ domain, body }) =>
  async () =>
    await request({
      path: `/v1/contacts/webpage/${domain}`,
      method: 'POST',
      body
    });

export const fetchSetting = id => async () =>
  await request({
    path: `/v1/settings/${id}`
  });

export const submitSoaRequest = (body, domain_name) => async () =>
  await request({
    path: `/v1/soa_requests/${body.id}/submit?domain_name=${domain_name}`,
    method: 'POST',
    body
  });

export const viewSoaRequest = (id, domain_name) => async () =>
  await request({
    path: `/v1/soa_requests/${id}/view?domain_name=${domain_name}`
  });
