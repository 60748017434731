import {
  DIALER_ORDERS,
  DIALER_SUBSCIPTIONS,
  DIALER_USAGE,
  DIALER_PLANS,
  DIALER_PLAN
} from '@/store/actions/admin/dialer.actions';

const initialState = {
  orders: [],
  subscriptions: [],
  usage: {},
  plans: [],
  plans_meta: {},
  plan: {}
};

export default function dialer(state = initialState, action) {
  switch (action.type) {
    case DIALER_ORDERS:
      return {
        ...state,
        orders: action.payload.data
      };
    case DIALER_SUBSCIPTIONS:
      return {
        ...state,
        subscriptions: action.payload.data
      };
    case DIALER_USAGE:
      return {
        ...state,
        usage: action.payload.data
      };
    case DIALER_PLANS:
      return {
        ...state,
        plans: action.payload.data,
        plans_meta: action.payload.meta
      };
    case DIALER_PLAN:
      return {
        ...state,
        plan: action.payload.data
      };
    default:
      return state;
  }
}
