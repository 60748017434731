import React, { Component } from 'react';
import * as Sentry from '@sentry/nextjs';

import styles from './ErrorBoundary.module.scss';

class ErrorBoundary extends Component {
  state = { hasErrorBoundary: false, error: null };

  static getDerivedStateFromError(error) {
    return { hasErrorBoundary: true, error };
  }

  componentDidCatch(error, errorInfo) {
    if (process.env.NODE_ENV !== 'development') {
      const scope = Sentry.getCurrentScope();
      if (scope) {
        Object.keys(errorInfo).forEach(key => {
          scope.setExtra(key, errorInfo[key]);
        });
      }
      if (!error.message.includes('Request failed')) {
        Sentry.captureException(error);
      }
    }
  }

  render() {
    if (process.env.NODE_ENV === 'development') {
      return this.props.children;
    }
    if (typeof window === 'undefined') {
      // Render null during SSR
      return null;
    }
    if (this.state.hasErrorBoundary) {
      return (
        <div className={styles.error}>
          <h6>An Error Occurred</h6>
          <p>The application detected an error, and it's been reported to the application development team.</p>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
