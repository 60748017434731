import {
  SMS_NUMBERS,
  SMS_ORDERS,
  SMS_SUBSCIPTIONS,
  SMS_USAGE,
  SMS_PLANS,
  SMS_PLAN
} from '@/store/actions/admin/sms.actions';

const initialState = {
  numbers: [],
  orders: [],
  subscriptions: [],
  usage: {},
  plans: [],
  plans_meta: {},
  plan: {}
};

export default function sms(state = initialState, action) {
  switch (action.type) {
    case SMS_NUMBERS:
      return {
        ...state,
        numbers: action.payload.data
      };
    case SMS_ORDERS:
      return {
        ...state,
        orders: action.payload.data
      };
    case SMS_SUBSCIPTIONS:
      return {
        ...state,
        subscriptions: action.payload.data
      };
    case SMS_USAGE:
      return {
        ...state,
        usage: action.payload.data
      };
    case SMS_PLANS:
      return {
        ...state,
        plans: action.payload.data,
        plans_meta: action.payload.meta
      };
    case SMS_PLAN:
      return {
        ...state,
        plan: action.payload.data
      };
    default:
      return state;
  }
}
