import { config } from '@/config';

export const setItem = (key, data) => {
  return localStorage.setItem(keyForUser(key), data);
};

export const getItem = (key, userId = getCurrentUserId()) => {
  return localStorage.getItem(keyForUser(key, userId));
};

export const getLoginStack = () => {
  if (typeof localStorage === 'undefined') return;
  const stack = JSON.parse(localStorage.getItem('loginStack')) || [];
  const length = stack.length;
  return {
    length,
    push: val => {
      stack.push(val);
      localStorage.setItem('loginStack', JSON.stringify(stack));
    },
    pop: () => {
      stack.pop();
      localStorage.setItem('loginStack', JSON.stringify(stack));
    },
    peek: () => stack[length - 1]
  };
};

export const removeItem = key => {
  return localStorage.removeItem(keyForUser(key));
};

export const setSessionItem = (key, data) => {
  return sessionStorage.setItem(keyForUser(key), data);
};

export const getSessionItem = (key, userId = getCurrentUserId()) => {
  return sessionStorage.getItem(keyForUser(key, userId));
};

export const removeSessionItem = key => {
  return sessionStorage.removeItem(keyForUser(key));
};

const CURRENT_USER_ID = 'CURRENT_USER_ID';

export const setCurrentUserId = id => {
  return localStorage.setItem(CURRENT_USER_ID, id);
};

const getCurrentUserId = () => {
  return localStorage.getItem(CURRENT_USER_ID);
};

const keyForUser = (key, id = getCurrentUserId()) => {
  return `${id}-${key}-${config().SYSTEM_VERSION[config().RAILS_ENV] || config().SYSTEM_VERSION.default}`;
};
