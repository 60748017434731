import React, { Component } from 'react';
import { withErrorBoundary } from '@/utils/errors';
import classNames from 'classnames';
import styles from './DialerActive.module.scss';
import Icon from '../Helpers/icon';

class DialerActive extends Component {
  state = {
    debug: false,
    callStartDate: null,
    callDuration: null
  };

  componentDidMount() {
    // this.startCallDurationCounter();
  }

  componentWillUnmount() {
    // this.clearCallDurationCounter();
  }

  setCallDurationValue() {
    // Get active call start time
    const start = new Date(this.state.callStartDate).getTime();
    // Get current time
    const end = new Date(Date.now()).getTime();
    // Calculate the difference
    const diff = end - start;
    // Extract the time in seconds
    const seconds = Math.floor((diff / 1000) % 60);
    // Extract the display minutes using the seconds
    const durationMin = parseInt(seconds / 60, 10).toString(10);
    // Extract the display seconds using the seconds
    const durationSeconds = (((seconds / 60) % 1) * 0.6).toString(10).slice(2, 4);

    // Set duration
    this.setState({ callDuration: durationMin + ':' + durationSeconds });
  }

  setCalDurationFormat(value) {
    const minutes = Math.floor(value / 60);
    const seconds = value % 60;
    return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  }

  render() {
    return (
      <div className={classNames(styles.DialerActiveWrapper)}>
        <div className={classNames(styles.DialerActiveIconContainer)}>
          <div className={classNames(styles.DialerActiveIcon)}>
            <i className="fa-solid fa-phone"></i>
          </div>
        </div>

        <div className={classNames(styles.DialerActiveInfoContainer)}>
          <div className={classNames(styles.DialerActiveInfo)}>
            <h2 className={classNames(styles.DialerActiveInfoNumber)}>{this.props.phoneNumber}</h2>
            <p className={classNames(styles.DialerActiveInfoDuration)}>
              {this.setCalDurationFormat(this.props.callDuration)}
            </p>
          </div>
        </div>

        <div className={classNames(styles.DialerActiveActionContainer)}>
          <div className={classNames(styles.DialerActiveAction)}>
            <div className={classNames(styles.DialerActiveActionItem)}>
              {/* New */}
              <div
                className={classNames(styles.DialerActiveActionItemButton)}
                onClick={() => this.props.onShowDialpad()}
              >
                <Icon icon={'dialpad'} size={40} color={'#474747'} bg={'white'} circle={true} square={false} />
                <p className={classNames(styles.DialerActiveActionItemLabel)}>Dialpad</p>
              </div>
            </div>
            <div className={classNames(styles.DialerActiveActionItem)}>
              {/* New */}
              <div className={classNames(styles.DialerActiveActionItemButton)} onClick={() => this.props.onRecord()}>
                {this.props.isRecording ? (
                  <>
                    <Icon icon={'record'} size={40} color={'red'} bg={'white'} circle={true} square={false} />
                    <p className={classNames(styles.DialerActiveActionItemLabel)}>Recording</p>
                  </>
                ) : (
                  <>
                    <Icon icon={'record'} size={40} color={'#474747'} bg={'white'} circle={true} square={false} />
                    <p className={classNames(styles.DialerActiveActionItemLabel)}>Recording</p>
                  </>
                )}
              </div>
            </div>
            <div className={classNames(styles.DialerActiveActionItem)}>
              {/* New */}
              <div className={classNames(styles.DialerActiveActionItemButton)} onClick={() => this.props.onHold()}>
                {this.props.isHold ? (
                  <>
                    <Icon icon={'mute'} size={40} color={'red'} bg={'white'} circle={true} square={false} />
                    <p className={classNames(styles.DialerActiveActionItemLabel)}>Unmute</p>
                  </>
                ) : (
                  <>
                    <Icon icon={'unmute'} size={40} color={'#474747'} bg={'white'} circle={true} square={false} />
                    <p className={classNames(styles.DialerActiveActionItemLabel)}>Mute</p>
                  </>
                )}
              </div>
            </div>
            <div className={classNames(styles.DialerActiveActionItem)}>
              {/* New */}
              <div className={classNames(styles.DialerActiveActionItemButton)} onClick={() => this.props.onHangup()}>
                {/* <mad-icon icon="phone-hangup" size="40" bg="white" color="red" circle="true"></mad-icon> */}
                <Icon icon={'phone-hangup'} size={40} color={'red'} bg={'white'} circle={true} square={false} />
                <p className={classNames(styles.DialerActiveActionItemLabel)}>Hangup</p>
              </div>
            </div>
          </div>
        </div>

        <div className={classNames(styles.DialerActiveActionsTagContainer)}>
          <div className={classNames(styles.DialerActiveActionsTag)}></div>
        </div>
      </div>
    );
  }
}

export default withErrorBoundary(DialerActive);
