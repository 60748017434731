import {
  CURRENT_ACCOUNT_INFO,
  PLANS,
  PAYMENT_INFOS,
  SAVE_CHOSEN_PLAN,
  CLEAR_CHOSEN_PLAN
} from '@/store/actions/currentAccount.actions';

const initialState = {
  info: {},
  plans: [],
  payment_infos: [],
  chosen_plan: {}
};

export default function currentAccount(state = initialState, action) {
  switch (action.type) {
    case CURRENT_ACCOUNT_INFO:
      return {
        ...state,
        info: action.payload.data
      };
    case PLANS:
      return {
        ...state,
        plans: action.payload.data
      };
    case PAYMENT_INFOS:
      return {
        ...state,
        payment_infos: action.payload.data,
        payment_infos_meta: action.payload.meta
      };
    case SAVE_CHOSEN_PLAN:
      return {
        ...state,
        chosen_plan: action.payload
      };
    case CLEAR_CHOSEN_PLAN:
      return {
        ...state,
        chosen_plan: {}
      };
    default:
      return state;
  }
}
