import { AUTHORIZATIONS, CALENDARS, CLEAR_CALENDARS } from '@/store/actions/authorization.actions';

const initialState = {
  authorizations: [],
  calendars: []
};

export default function authorization(state = initialState, action) {
  switch (action.type) {
    case AUTHORIZATIONS:
      return {
        ...state,
        authorizations: action.payload.data
      };
    case CALENDARS:
      return {
        ...state,
        calendars: action.payload.data
      };
    case CLEAR_CALENDARS:
      return {
        ...state,
        calendars: []
      };
    default:
      return state;
  }
}
