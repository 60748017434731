import {
  EVENTS,
  UPDATE_EVENT,
  CONTACT_EVENTS,
  PARTICULAR_EVENTS,
  ACTIVE_CALENDAR_RANGE
} from '@/store/actions/event.actions';

const initialState = {
  events: [],
  contact_events: [],
  particular_events: [],
  activeCalendarRange: {}
};

export default function event(state = initialState, action) {
  switch (action.type) {
    case EVENTS:
      return {
        ...state,
        events: action.payload.data
      };
    case UPDATE_EVENT:
      const updatedIndex = state.events.findIndex(event => event.id === action.payload.data.id);
      if (updatedIndex !== -1) {
        const updatedEvents = state.events.map(event =>
          event.id === action.payload.data.id ? action.payload.data : event
        );
        return {
          ...state,
          events: updatedEvents
        };
      } else {
        return state;
      }
    case ACTIVE_CALENDAR_RANGE:
      return {
        ...state,
        activeCalendarRange: action.payload
      };
    case CONTACT_EVENTS:
      return {
        ...state,
        contact_events: action.payload.data
      };
    case PARTICULAR_EVENTS:
      return {
        ...state,
        particular_events: action.payload.data
      };
    default:
      return state;
  }
}
