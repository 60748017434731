import request from '@/utils/request';

export const DIALER_USAGE = 'DIALER_USAGE';
export const DIALER_SUBSCIPTIONS = 'DIALER_SUBSCIPTIONS';
export const DIALER_ORDERS = 'DIALER_ORDERS';
export const DIALER_PLANS = 'DIALER_PLANS';
export const DIALER_PLAN = 'DIALER_PLAN';

export const fetchUsage = () => async dispatch => {
  const response = await request({
    path: `/v1/admin/dialer/usage`
  });
  dispatch({
    payload: response,
    type: DIALER_USAGE
  });
};

export const fetchSubscriptions = () => async dispatch => {
  const response = await request({
    path: `/v1/admin/dialer/subscriptions`
  });
  dispatch({
    payload: response,
    type: DIALER_SUBSCIPTIONS
  });
};

export const updateSubscription = body => async () =>
  await request({
    path: `/v1/admin/dialer/subscriptions`,
    method: 'POST',
    body
  });

export const activateSubscription = body => async () =>
  await request({
    path: `/v1/admin/dialer/active_subscription`,
    method: 'POST',
    body
  });

export const cancelSubscription = body => async () =>
  await request({
    path: `/v1/admin/dialer/cancel_subscription`,
    method: 'POST',
    body
  });

export const fetchOrders = () => async dispatch => {
  const response = await request({
    path: `/v1/admin/dialer/orders`
  });
  dispatch({
    payload: response,
    type: DIALER_ORDERS
  });
};

export const updateOrder = body => async () =>
  await request({
    path: `/v1/admin/dialer/orders`,
    method: 'POST',
    body
  });

export const cancelOrder = body => async () =>
  await request({
    path: `/v1/admin/dialer/cancel_order`,
    method: 'POST',
    body
  });

export const fetchDialerPlans =
  ({ q, order, page, limit, all }) =>
  async dispatch => {
    const response = await request({
      path: `/v1/admin/dialer/dialer_plans`,
      query: { q, order, page, limit, all }
    });
    dispatch({ payload: response, type: DIALER_PLANS });
  };

export const fetchDialerPlan =
  ({ id }) =>
  async dispatch => {
    const response = await request({
      path: `/v1/admin/dialer/dialer_plans/${id}`
    });
    dispatch({
      payload: response,
      type: DIALER_PLAN
    });
  };

export const createDialerPlan = body => async () =>
  await request({
    path: `/v1/admin/dialer/dialer_plans`,
    method: 'POST',
    body
  });

export const updateDialerPlan = (id, body) => async () =>
  await request({
    path: `/v1/admin/dialer/dialer_plans/${id}`,
    method: 'PATCH',
    body
  });

export const deleteDialerPlan = id => async () =>
  await request({
    path: `/v1/admin/dialer/dialer_plans/${id}`,
    method: 'DELETE'
  });
