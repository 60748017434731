import {
  DEVICE_ENABLED,
  DEVICE_TOKEN,
  DEVICE_STATUS,
  DEVICE_VISIBLE,
  CALL_STATUS,
  CALL_LIST,
  CALL_HISTORY,
  ASSOCIATED_CONTACT,
  ASSOCIATED_CONTACTS,
  DIALER_PLANS,
  DIALER_SUBSCIPTIONS,
  DIALER_SUBSCIPTION,
  DIALER_PENDING_COMPANY_SUBSCIPTION,
  DIALER_SUBSCIPTION_COMPANY,
  DIALER_USAGE,
  DIALER_LICENSES,
  DIALER_LICENSE,
  DIALER_ORDERS,
  DIALER_ORDER
} from '@/store/actions/dialer.actions';

import { DEVICE_STATUSES, CALL_STATUSES, CALL_STATE, CALL_TYPES, CALL_ERRORS } from '@/store/models/dialer';

const initialState = {
  enabled: false,
  visible: false,
  token: '',
  status: DEVICE_STATUSES.READY,
  call_status: CALL_STATUSES.INACTIVE,
  call_list: [],
  call_history: [],
  associated_contact: {},
  associated_contacts: [],
  plans: [],
  subscriptions: [],
  subscriptions_meta: [],
  subscription: {},
  pending_company_subscription: {},
  usage: [],
  orders: [],
  orders_meta: [],
  order: {},
  licenses: [],
  licenses_meta: [],
  license: {}
};

export default function dialer(state = initialState, action) {
  switch (action.type) {
    case DEVICE_ENABLED:
      return {
        ...state,
        enabled: action.payload
      };
    case DEVICE_VISIBLE:
      return {
        ...state,
        visible: action.payload
      };
    case DEVICE_STATUS:
      return {
        ...state,
        visible: action.payload
      };
    case DEVICE_TOKEN:
      return {
        ...state,
        token: action.payload
      };
    case CALL_STATUS:
      return {
        ...state,
        call_status: action.payload
      };
    case CALL_LIST:
      return {
        ...state,
        call_list: action.payload
      };
    case CALL_HISTORY:
      return {
        ...state,
        call_history: action.payload
      };
    case ASSOCIATED_CONTACT:
      return {
        ...state,
        associated_contact: action.payload
      };
    case ASSOCIATED_CONTACTS:
      return {
        ...state,
        associated_contacts: action.payload
      };
    case DIALER_PLANS:
      return {
        ...state,
        plans: action.payload.data
      };
    case DIALER_SUBSCIPTIONS:
      return {
        ...state,
        subscriptions: action.payload.data,
        subscriptions_meta: action.payload.meta
      };
    case DIALER_SUBSCIPTION:
      return {
        ...state,
        subscription: action.payload.data
      };
    case DIALER_PENDING_COMPANY_SUBSCIPTION:
      return {
        ...state,
        pending_company_subscription: action.payload?.data || {}
      };
    case DIALER_SUBSCIPTION_COMPANY:
      return {
        ...state,
        company_subscription: action.payload.data
      };
    case DIALER_USAGE:
      return {
        ...state,
        usage: action.payload.data
      };

    case DIALER_LICENSES:
      return {
        ...state,
        licenses: action.payload.data
      };
    case DIALER_LICENSE:
      return {
        ...state,
        license: action.payload.data
      };
    case DIALER_ORDERS:
      return {
        ...state,
        orders: action.payload.data
      };
    case DIALER_ORDER:
      return {
        ...state,
        order: action.payload.data
      };
    default:
      return state;
  }
}
