import { config } from '@/config';

const HelpButton = () => {
  const script = document.createElement('script');

  script.src = `https://widget.freshworks.com/widgets/${config().CAREINADVANCE ? '47000005999' : '47000005615'}.js`;
  script.async = true;

  document.body.appendChild(script);
  window.fwSettings = {
    widget_id: config().CAREINADVANCE ? 47000005999 : 47000005615
  };

  !(function () {
    if ('function' != typeof window.FreshworksWidget) {
      var n = function () {
        n.q.push(arguments);
      };
      (n.q = []), (window.FreshworksWidget = n);
    }
  })();
};

export default HelpButton;
