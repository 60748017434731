import {
  USERS,
  USER,
  POOLS,
  CATEGORY,
  PRODUCTS,
  PRODUCT,
  PAYMENT_INFOS,
  CORPORATIONS,
  CORPORATION,
  ORDER,
  BROADCAST_EMAILS,
  BROADCAST_EMAIL,
  GROUPS,
  GROUP,
  POOL,
  SIGNUPS,
  BUSINESS_UNITS,
  BUSINESS_UNIT,
  ACTIVE_FEATURE_FLAG,
  CAMPAIGN_TRIGGERS,
  CAMPAIGN_TRIGGER,
  FEATURE_FLAGS
} from '@/store/actions/admin/users.actions';

const initialState = {
  users: [],
  user: {},
  metadata: {},
  pools: [],
  pool: {},
  category: {},
  products: [],
  products_meta: {},
  product: {},
  payment_infos: [],
  payment_infos_meta: {},
  corporations: [],
  corporation: {},
  order: {},
  broadcast_emails: [],
  broadcast_email: {},
  groups: [],
  group: {},
  business_units: [],
  business_unit: {},
  active_feature_flag: '',
  feature_flags: [],
  signups: [],
  signups_meta: {},
  campaign_triggers: [],
  campaign_triggers_meta: {},
  campaign_trigger: {}
};

export default function users(state = initialState, action) {
  switch (action.type) {
    case USERS:
      return {
        ...state,
        users: action.payload.data,
        metadata: action.payload.meta
      };
    case USER:
      return {
        ...state,
        user: action.payload.data
      };
    case POOLS:
      return {
        ...state,
        pools: action.payload.data
      };
    case CATEGORY:
      return {
        ...state,
        category: action.payload.data
      };
    case PRODUCTS:
      return {
        ...state,
        products: action.payload.data,
        products_meta: action.payload.meta
      };
    case PRODUCT:
      return {
        ...state,
        product: action.payload.data
      };
    case PAYMENT_INFOS:
      return {
        ...state,
        payment_infos: action.payload.data,
        payment_infos_meta: action.payload.meta
      };
    case CORPORATIONS:
      return {
        ...state,
        corporations: action.payload.data
      };
    case CORPORATION:
      return {
        ...state,
        corporation: action.payload.data
      };
    case ORDER:
      return {
        ...state,
        order: action.payload.data
      };
    case BROADCAST_EMAILS:
      return {
        ...state,
        broadcast_emails: action.payload.data
      };
    case BROADCAST_EMAIL:
      return {
        ...state,
        broadcast_email: action.payload.data
      };
    case GROUPS:
      return {
        ...state,
        groups: action.payload.data
      };
    case GROUP:
      return {
        ...state,
        group: action.payload.data
      };
    case POOL:
      return {
        ...state,
        pool: action.payload.data
      };
    case BUSINESS_UNITS:
      return {
        ...state,
        business_units: action.payload.data
      };
    case BUSINESS_UNIT:
      return {
        ...state,
        business_unit: action.payload.data
      };
    case ACTIVE_FEATURE_FLAG:
      return {
        ...state,
        active_feature_flag: action.payload?.data
      };
    case SIGNUPS:
      return {
        ...state,
        signups: action.payload.data,
        signups_meta: action.payload.meta
      };
    case CAMPAIGN_TRIGGERS:
      return {
        ...state,
        campaign_triggers: action.payload.data,
        campaign_triggers_meta: action.payload.meta
      };
    case CAMPAIGN_TRIGGER:
      return {
        ...state,
        campaign_trigger: action.payload.data
      };
    case FEATURE_FLAGS:
      return {
        ...state,
        feature_flags: action.payload.data
      };
    default:
      return state;
  }
}
