import { config } from '@/config';

export const DEFAULT_TIME_ZONE = 'est';
export const TIME_ZONE = [
  { value: 'est', label: 'Eastern' },
  { value: 'cst', label: 'Central' },
  { value: 'mst', label: 'Mountain' },
  { value: 'pst', label: 'Pacific' },
  { value: 'akst', label: 'Alaska' },
  { value: 'hst', label: 'Hawaii' }
];

export const SPOUSE = [
  { label: 'No', value: false },
  { label: 'Yes', value: true }
];

export const DEFAULT_GENDER = { value: 'male', label: 'Male' };

export const GENDER = [DEFAULT_GENDER, { value: 'female', label: 'Female' }, { value: 'other', label: 'Empty' }];

export const DEFAULT_TITLE = { value: 'representative', label: 'Representative' };

const CAREINADVANCE_TITLE = config().CAREINADVANCE
  ? [
      { value: 'funeral_director', label: 'Funeral Director' },
      { value: 'preneed_agent', label: 'Preneed Agent' },
      { value: 'preneed_agent_and_funeral_director', label: 'Preneed Agent & Funeral Director' },
      { value: 'advanced_planning_specialist', label: 'Advanced Planning Specialist' },
      { value: 'prearrangement_specialist', label: 'Pre-arrangement Specialist' }
    ]
  : [];

const ASBLEGACY_TITLE = config().ASBLEGACY
  ? [{ value: 'licensed_insurance_agent', label: 'Licensed Insurance Agent' }]
  : [];

export const TITLE = [
  DEFAULT_TITLE,
  { value: 'insurance_agent', label: 'Insurance Agent' },
  { value: 'regional_sales_manager', label: 'Regional Sales Manager' },
  { value: 'field_manager', label: 'Field Manager' },
  { value: 'district_manager', label: 'District Manager' },
  { value: 'advisor', label: 'Advisor' },
  { value: 'retirement_specialist', label: 'Retirement Specialist' },
  ...CAREINADVANCE_TITLE,
  ...ASBLEGACY_TITLE
];

export const ADMIN = 'admin';
export const SUB_ADMIN = 'sub_admin';
export const BU_ADMIN = 'bu_admin';
export const MANAGER = 'manager';
export const REGIONAL_MANAGER = 'regional_manager';
export const DISTRICT_MANAGER = 'district_manager';
export const FIELD_MANAGER = 'field_manager';
export const TELEMARKETER = 'telemarketer';
export const AGENT = 'agent';

const REGIONAL_MANAGER_ACCOUNT_TYPE = config().RM ? [{ value: REGIONAL_MANAGER, label: 'Regional Manager' }] : [];

export const ALL_ACCOUNT_TYPES = [
  { value: ADMIN, label: 'System Admin' },
  { value: SUB_ADMIN, label: 'Admin' },
  { value: BU_ADMIN, label: 'BU Admin' },
  { value: MANAGER, label: 'Manager' },
  ...REGIONAL_MANAGER_ACCOUNT_TYPE,
  { value: DISTRICT_MANAGER, label: 'District Manager' },
  { value: FIELD_MANAGER, label: 'Field Manager' },
  { value: TELEMARKETER, label: 'Telemarketer' },
  { value: AGENT, label: 'Agent' }
];
export const ACCOUNT_TYPES_FOR_COMPANY_FOR_ADMIN = [
  { value: ADMIN, label: 'System Admin' },
  { value: SUB_ADMIN, label: 'Admin' },
  { value: MANAGER, label: 'Manager' },
  ...REGIONAL_MANAGER_ACCOUNT_TYPE,
  { value: DISTRICT_MANAGER, label: 'District Manager' },
  { value: FIELD_MANAGER, label: 'Field Manager' },
  { value: TELEMARKETER, label: 'Telemarketer' },
  { value: AGENT, label: 'Agent' }
];

export const ACCOUNT_TYPES_FOR_COMPANY_FOR_RM = [{ value: REGIONAL_MANAGER, label: 'Regional Manager' }];
export const ACCOUNT_TYPES_FOR_COMPANY_FOR_MANAGER = [
  { value: MANAGER, label: 'Manager' },
  { value: DISTRICT_MANAGER, label: 'District Manager' },
  { value: FIELD_MANAGER, label: 'Field Manager' },
  { value: TELEMARKETER, label: 'Telemarketer' },
  { value: AGENT, label: 'Agent' }
];

export const ACCOUNT_TYPES_FOR_BUSINESS_UNIT_FOR_BU_ADMIN = [
  { value: MANAGER, label: 'Manager' },
  ...REGIONAL_MANAGER_ACCOUNT_TYPE,
  { value: DISTRICT_MANAGER, label: 'District Manager' },
  { value: FIELD_MANAGER, label: 'Field Manager' },
  { value: TELEMARKETER, label: 'Telemarketer' },
  { value: AGENT, label: 'Agent' }
];

export const ACCOUNT_TYPE_WITHOUT_OWNER = [
  { value: ADMIN, label: 'System Admin' },
  { value: SUB_ADMIN, label: 'Admin' },
  { value: BU_ADMIN, label: 'Bu Admin' },
  ...REGIONAL_MANAGER_ACCOUNT_TYPE
];

export const FIRELIGHT_PHONE_TYPES = [
  { value: 2, label: 'Mobile' },
  { value: 9, label: 'Other' }
];

export const DEFAULT_STATE = { value: '', label: 'Select A State' };

export const STATES = [
  { value: '', label: 'Select A State' },
  { value: 'AL', label: 'Alabama' },
  { value: 'AK', label: 'Alaska' },
  { value: 'AR', label: 'Arkansas' },
  { value: 'AZ', label: 'Arizona' },
  { value: 'CA', label: 'California' },
  { value: 'CO', label: 'Colorado' },
  { value: 'CT', label: 'Connecticut' },
  { value: 'DC', label: 'District of Columbia' },
  { value: 'DE', label: 'Delaware' },
  { value: 'FL', label: 'Florida' },
  { value: 'GA', label: 'Georgia' },
  { value: 'HI', label: 'Hawaii' },
  { value: 'IA', label: 'Iowa' },
  { value: 'ID', label: 'Idaho' },
  { value: 'IL', label: 'Illinois' },
  { value: 'IN', label: 'Indiana' },
  { value: 'KS', label: 'Kansas' },
  { value: 'KY', label: 'Kentucky' },
  { value: 'LA', label: 'Louisiana' },
  { value: 'MA', label: 'Massachusetts' },
  { value: 'MD', label: 'Maryland' },
  { value: 'ME', label: 'Maine' },
  { value: 'MI', label: 'Michigan' },
  { value: 'MN', label: 'Minnesota' },
  { value: 'MO', label: 'Missouri' },
  { value: 'MS', label: 'Mississippi' },
  { value: 'MT', label: 'Montana' },
  { value: 'NC', label: 'North Carolina' },
  { value: 'ND', label: 'North Dakota' },
  { value: 'NE', label: 'Nebraska' },
  { value: 'NH', label: 'New Hampshire' },
  { value: 'NJ', label: 'New Jersey' },
  { value: 'NM', label: 'New Mexico' },
  { value: 'NV', label: 'Nevada' },
  { value: 'NY', label: 'New York' },
  { value: 'OH', label: 'Ohio' },
  { value: 'OK', label: 'Oklahoma' },
  { value: 'OR', label: 'Oregon' },
  { value: 'PA', label: 'Pennsylvania' },
  { value: 'RI', label: 'Rhode Island' },
  { value: 'SC', label: 'South Carolina' },
  { value: 'SD', label: 'South Dakota' },
  { value: 'TN', label: 'Tennessee' },
  { value: 'TX', label: 'Texas' },
  { value: 'UT', label: 'Utah' },
  { value: 'VA', label: 'Virginia' },
  { value: 'VT', label: 'Vermont' },
  { value: 'WA', label: 'Washington' },
  { value: 'WI', label: 'Wisconsin' },
  { value: 'WV', label: 'West Virginia' },
  { value: 'WY', label: 'Wyoming' }
];

export const TIMEZONE_LIST = [
  {
    value: 'est',
    name: 'US/Eastern'
  },
  {
    value: 'est',
    name: 'US/East-Indiana'
  },
  {
    value: 'cst',
    name: 'US/Central'
  },
  {
    value: 'mst',
    name: 'US/Mountain'
  },
  {
    value: 'pst',
    name: 'US/Pacific'
  },
  {
    value: 'akst',
    name: 'US/Alaska'
  },
  {
    value: 'hst',
    name: 'US/Hawaii'
  }
];

export const MAIN_OR_BRANCH = [
  {
    value: 'main',
    label: 'Main'
  },
  {
    value: 'branch',
    label: 'Branch'
  }
];

export const LANGUAGES = [
  { value: 'english', label: 'English' },
  { value: 'spanish', label: 'Spanish' },
  { value: 'french', label: 'French' },
  { value: 'chinese', label: 'Chinese' },
  { value: 'korean', label: 'Korean' },
  { value: 'german', label: 'German' }

  // TODO: populate more...
];
