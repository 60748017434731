import request from '@/utils/request';

export const TRANSFER_ATTEMPTS = 'TRANSFER_ATTEMPTS';
export const TRANSFER_ATTEMPT = 'TRANSFER_ATTEMPT';
export const UNLOAD_TRANSFER_ATTEMPT = 'UNLOAD_TRANSFER_ATTEMPT';
export const REVERT_TRANSFER_ATTEMPT = 'REVERT_TRANSFER_ATTEMPT';
export const CLEAR_REVERT = 'CLEAR_REVERT';

export const fetchTransferAttempts =
  ({ limit, q, page, order_field, order }) =>
  async dispatch => {
    const response = await request({
      path: `/v1/contacts/transfer_attempts`,
      query: { limit, order_field, order, q, page }
    });
    dispatch({
      payload: response,
      type: TRANSFER_ATTEMPTS
    });
  };

export const fetchTransferAttempt =
  ({ id, params }) =>
  async dispatch => {
    const [{ data: transfer }, { data: logs, meta }] = await Promise.all([
      request({
        path: `/v1/contacts/transfer_attempts/${id}`
      }),
      request({
        path: `/v1/contacts/transfer_attempts/${id}/logs`,
        query: params
      })
    ]);

    const transferAttemptWithLogs = {
      data: { ...transfer, transfer_logs: logs },
      meta
    };

    dispatch({ payload: transferAttemptWithLogs, type: TRANSFER_ATTEMPT });
    return transferAttemptWithLogs;
  };

export const revertTransferAttempt = transfer_attempt_id => async dispatch => {
  dispatch({
    payload: transfer_attempt_id,
    type: REVERT_TRANSFER_ATTEMPT
  });
  const response = await request({
    path: `/v1/contacts/transfer_attempts/${transfer_attempt_id}`,
    method: 'POST'
  });
  return response;
};

export const unloadTransferAttempt = () => ({ type: UNLOAD_TRANSFER_ATTEMPT });

export const clearRevert = () => ({ type: CLEAR_REVERT });
