import {
  CONTACTS,
  CONTACTS_LOAD_MORE,
  CONTACTS_META,
  CONTACTS_MERGE,
  UPDATE_CONTACT,
  DELETE_CONTACTS,
  CONTACT,
  CONTACTS_SELECTED_ITEMS,
  NOTES,
  CREATE_NOTES,
  UPDATE_NOTES,
  FILES,
  TAGS,
  NEW_TAG,
  DATA_CAPTURE,
  CLEAR_DATA_CAPTURE,
  POLICIES,
  SECURITIES,
  PARTICULAR_POLICY,
  TRANSFER_GROUP,
  CLEAR_TRANSFER_GROUP,
  MAILER_ASSIGNMENT_KITS,
  MAILER_ASSIGNMENT_COUNTS,
  IMPORT_FILE,
  IMPORT_ATTACHMENTS,
  IMPORT_FORM,
  IMPORT_ATTEMPTS,
  PROCESSING_IMPORT_ATTEMPTS,
  EMAIL_LOGS,
  MAILING_GROUP,
  EMAILS,
  EMAIL_CAMPAIGN_TRIGGERS,
  EMAIL_HISTORY,
  TRANSFER_HISTORY,
  CALENDAR_HISTORY,
  SET_CONTACT_TO_STORE,
  SEND_EMAIL,
  OPEN_PROCESS_NOTIFICATION,
  WORKERS_STATUS,
  CONTACT_STATUSES,
  CONTACT_SOURCES,
  CALL_FILES,
  MESSAGES,
  SEND_MESSAGE,
  SMS_GROUP,
  CONTACTS_CAMPAIGN_TRIGGERS,
  NEW_TRANSFER_ATTEMPT,
  CLEAR_TRANSFER_ATTEMPT,
  TRANSFER_ATTEMPT_STATUS,
  OPTIMISTIC_UPDATE_TRANSFER_ATTEMPT,
  CLOSE_PROCESS_NOTIFICATION,
  RX_DETAILS,
  CSG_PLANS,
  CSG_PLAN_DETAILS,
  CSG_QUOTE
} from '@/store/actions/contacts.actions';

const initialState = {
  contacts: [],
  selected_contacts: [],
  metadata: {},
  contacts_merge: [],
  contact: {},
  notes: [],
  files: [],
  files_meta: [],
  tags: [],
  new_tag: {},
  data_captures: [],
  policies: [],
  policies_meta: {},
  securities_meta: {},
  securities: [],
  particular_policy: {},
  transfer_group: {},
  mailer_assignment_kits: {},
  mailer_assignment_counts: {},
  import_file: {},
  import_attachments: [],
  import_form: { fields: [] },
  import_attempts: [],
  import_attempts_meta: {},
  processing_import_attempts: [],
  email_logs: [],
  mailing_group: {},
  emails: [],
  email_campaign_triggers: [],
  email_history: [],
  email_history_meta: {},
  calendar_history: [],
  transfer_history: [],
  transfer_attempt: null,
  search: '',
  send_email: {},
  process_notification: {
    visible: false,
    status: 'processing',
    text: 'Loading...',
    linkUrl: '',
    linkText: ''
  },
  workers_status: {},
  contact_statuses: [],
  contact_sources: [],
  call_files: [],
  call_files_meta: [],
  messages: [],
  send_message: {},
  sms_group: {},
  campaign_triggers: [],
  csg_plans: [],
  csg_plan_details: {},
  csg_quote: [],
  rx_details: {}
};

export default function contacts(state = initialState, action) {
  switch (action.type) {
    case CONTACTS:
      return {
        ...state,
        contacts: action.payload.data || []
      };
    case CONTACTS_LOAD_MORE:
      return {
        ...state,
        contacts: [...state.contacts, ...action.payload.data]
      };
    case CONTACTS_META:
      return {
        ...state,
        metadata: action.payload.meta
      };
    case CONTACTS_MERGE:
      return {
        ...state,
        contacts_merge: action.payload.data
      };
    case UPDATE_CONTACT:
      if (!action.payload) return;
      return {
        ...state,
        contacts: state.contacts.map(contact =>
          contact.id === action.payload.data.id ? action.payload.data : contact
        ),
        contact: action.payload.data
      };
    case DELETE_CONTACTS:
      return {
        ...state,
        contacts: state.contacts.filter(contact => !action.payload.ids.includes(contact.id))
      };
    case DATA_CAPTURE:
      return {
        ...state,
        data_captures: action.payload.data
      };
    case CLEAR_DATA_CAPTURE:
      return {
        ...state,
        data_captures: []
      };
    case CONTACT:
      return {
        ...state,
        contact: action.payload.data
      };
    case CONTACTS_SELECTED_ITEMS:
      return {
        ...state,
        selected_contacts: action.payload.data
      };
    case NOTES:
      return {
        ...state,
        notes: action.payload.data || []
      };
    case CREATE_NOTES:
      return {
        ...state,
        notes: [action.payload.data, ...state.notes]
      };
    case UPDATE_NOTES:
      return {
        ...state,
        notes: [...state.notes].splice(
          state.notes.findIndex(note => note.id === action.payload.data.id),
          1,
          action.payload.data
        )
      };
    case FILES:
      return {
        ...state,
        files: action.payload.data || [],
        files_meta: action.payload.meta
      };
    case TAGS:
      return {
        ...state,
        tags: action.payload.data
      };
    case NEW_TAG:
      return {
        ...state,
        new_tag: action.payload.data
      };
    case POLICIES:
      return {
        ...state,
        policies: action.payload.data,
        policies_meta: action.payload.meta
      };
    case SECURITIES:
      return {
        ...state,
        securities: action.payload.data,
        securities_meta: action.payload.meta
      };
    case PARTICULAR_POLICY:
      return {
        ...state,
        particular_policy: action.payload.data
      };
    case TRANSFER_GROUP:
      return {
        ...state,
        transfer_group: action.payload.meta
      };
    case MAILER_ASSIGNMENT_KITS:
      return {
        ...state,
        mailer_assignment_kits: action.payload.meta
      };
    case MAILER_ASSIGNMENT_COUNTS:
      return {
        ...state,
        mailer_assignment_counts: action.payload.meta
      };
    case CLEAR_TRANSFER_GROUP:
      return {
        ...state,
        transfer_group: {}
      };
    case IMPORT_FILE:
      return {
        ...state,
        import_file: action.payload
      };
    case IMPORT_ATTACHMENTS:
      return {
        ...state,
        import_attachments: action.payload
      };
    case IMPORT_FORM:
      return {
        ...state,
        import_form: action.payload
      };
    case IMPORT_ATTEMPTS:
      return {
        ...state,
        import_attempts: action.payload.data,
        import_attempts_meta: action.payload.meta
      };
    case PROCESSING_IMPORT_ATTEMPTS:
      const index = state.processing_import_attempts.findIndex(el => el.id === action.payload.id);

      let processing_import_attempts = [...state.processing_import_attempts];
      if (index !== -1) {
        processing_import_attempts.splice(index, 1, action.payload);
      } else {
        processing_import_attempts.push(action.payload);
      }
      return {
        ...state,
        processing_import_attempts: processing_import_attempts
      };
    case EMAIL_LOGS:
      return {
        ...state,
        email_logs: action.payload
      };
    case MAILING_GROUP:
      return {
        ...state,
        mailing_group: action.payload
      };
    case EMAILS:
      return {
        ...state,
        emails: action.payload.data
      };
    case EMAIL_CAMPAIGN_TRIGGERS:
      return {
        ...state,
        email_campaign_triggers: action.payload.data
      };
    case EMAIL_HISTORY:
      return {
        ...state,
        email_history: action.payload.data || [],
        email_history_meta: action.payload.meta || {}
      };
    case CALENDAR_HISTORY:
      return {
        ...state,
        calendar_history: action.payload || []
      };
    case TRANSFER_HISTORY:
      return {
        ...state,
        transfer_history: action.payload
      };
    case SET_CONTACT_TO_STORE:
      return {
        ...state,
        search: action.payload
      };
    case SEND_EMAIL:
      return {
        ...state,
        send_email: action.payload
      };
    case OPEN_PROCESS_NOTIFICATION:
      return {
        ...state,
        process_notification: {
          visible: action.payload.visible,
          status: action.payload.status,
          text: action.payload.text,
          linkUrl: action.payload.linkUrl,
          linkText: action.payload.linkText
        }
      };
    case CLOSE_PROCESS_NOTIFICATION:
      return {
        ...state,
        process_notification: {
          visible: false,
          status: action.payload.status,
          text: '',
          linkUrl: '',
          linkText: ''
        }
      };
    case WORKERS_STATUS:
      return {
        ...state,
        workers_status: action.payload.data
      };
    case CONTACT_STATUSES:
      return {
        ...state,
        contact_statuses: action.payload.data?.map(item => ({ value: item.id, label: item.title })) || []
      };
    case CONTACT_SOURCES:
      return {
        ...state,
        contact_sources: action.payload.data?.map(item => ({ value: item.id, label: item.title })) || []
      };
    case CALL_FILES:
      return {
        ...state,
        call_files: action.payload.data,
        call_files_meta: action.payload.meta
      };
    case MESSAGES:
      return {
        ...state,
        messages: action.payload.data
      };
    case SEND_MESSAGE:
      return {
        ...state,
        send_message: action.payload.data
      };
    case SMS_GROUP:
      return {
        ...state,
        sms_group: action.payload
      };
    case CONTACTS_CAMPAIGN_TRIGGERS:
      return {
        ...state,
        campaign_triggers: action.payload.data
      };
    case CSG_PLANS:
      return {
        ...state,
        csg_plans: action.payload.data
      };
    case CSG_PLAN_DETAILS:
      return {
        ...state,
        csg_plan_details: action.payload.data
      };
    case CSG_QUOTE:
      return {
        ...state,
        csg_quote: action.payload.data
      };
    case NEW_TRANSFER_ATTEMPT:
      return {
        ...state,
        transfer_attempt: action.payload.data
      };
    case TRANSFER_ATTEMPT_STATUS:
      return {
        ...state,
        transfer_attempt: action.payload.data
      };
    case CLEAR_TRANSFER_ATTEMPT:
      return {
        ...state,
        transfer_attempt: null
      };
    case RX_DETAILS:
      return {
        ...state,
        rx_details: action.payload.data
      };
    case OPTIMISTIC_UPDATE_TRANSFER_ATTEMPT:
      if (state.transfer_attempt && !action.payload.revert) {
        return {
          ...state,
          transfer_attempt: { ...state.transfer_attempt, status: 'complete' }
        };
      }
      return state;
    default:
      return state;
  }
}

export const selectProcessNotification = state => state?.contacts.process_notification;
