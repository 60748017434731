import { WEBPAGE, PUBLIC_ERROR } from '@/store/actions/public.actions';

const initialState = {
  info: {},
  publicError: false
};

export default function publicReducer(state = initialState, action) {
  switch (action.type) {
    case WEBPAGE:
      return {
        ...state,
        info: action.payload.data
      };
    case PUBLIC_ERROR:
      return {
        ...state,
        publicError: true
      };
    default:
      return state;
  }
}
