export const RECONNECT_DELAY = 1000;
export const OPEN_NEW_TAB_DELAY = 500;
export const WEBSOCKET_C1_MESSAGES = {
  subscribe: { command: 'subscribe', identifier: JSON.stringify({ channel: 'ConvergeOneChannel' }) },
  validate_stream: {
    command: 'message',
    identifier: JSON.stringify({ channel: 'ConvergeOneChannel' }),
    data: JSON.stringify({ action: 'validate_stream', code: 'a' })
  }
};
export const WEBSOCKET_SMS_MESSAGES = {
  subscribe: { command: 'subscribe', identifier: JSON.stringify({ channel: 'SmsChannel' }) },
  validate_stream: {
    command: 'message',
    identifier: JSON.stringify({ channel: 'SmsChannel' }),
    data: JSON.stringify({ action: 'validate_stream', code: 'a' })
  }
};

export const WEBSOCKET_CONTACTS_IMPORT = {
  subscribe: { command: 'subscribe', identifier: JSON.stringify({ channel: 'ContactsImportChannel' }) },
  validate_stream: {
    command: 'message',
    identifier: JSON.stringify({ channel: 'ContactsImportChannel' }),
    data: JSON.stringify({ action: 'validate_stream', code: 'a' })
  }
};
