import React, { Component } from 'react';
import ErrorBoundary from '@/components/ErrorBoundary';
import { getDisplayName } from '@/utils/helpers';

export const handleError = ({ serverSide, response }) => {
  if (!serverSide && response && response.status === 401) {
    window.location = '/login';
  }
};

export const withErrorBoundary = WrappedComponent => {
  class WithErrorBoundary extends Component {
    render() {
      const { forwardedRef, ...rest } = this.props;
      return (
        <ErrorBoundary>
          <WrappedComponent ref={forwardedRef} {...rest} />
        </ErrorBoundary>
      );
    }
  }

  function forwardRef(props, ref) {
    return <WithErrorBoundary {...props} forwardedRef={ref} />;
  }

  forwardRef.displayName = `WithErrorBoundary(${getDisplayName(WrappedComponent)})`;

  return React.forwardRef(forwardRef);
};
