import fetch from 'isomorphic-unfetch';
import queryString from 'query-string';
import { handleError } from '@/utils/errors';
import { XCSRFHeader } from '@/utils/auth';
import getConfig from 'next/config';

const { publicRuntimeConfig } = getConfig();

const isServer = typeof window === 'undefined';

let globalController = [];

export let abortSignal = (targets = []) => {
  const exceptions = ['info', 'me'];

  if (!isServer) {
    globalController.forEach(controller => {
      if (
        !exceptions.some(exception => controller.url.match(exception)) &&
        (targets.length > 0 ? targets.some(target => controller.url.match(target)) : true)
      )
        controller?.controller?.abort();
    });
    globalController = [];
  }
};

export default async function request(params) {
  const {
    path,
    query,
    method = 'GET',
    headers = {},
    token = '',
    current_admin_token = '',
    impersonator_token = '',
    body = {},
    formDataBody = {},
    formData = false,
    fullResponse = false,
    signal = null
  } = params;
  let requestUrl = publicRuntimeConfig.API_URL + path;

  if (process.env.NODE_ENV === 'development') {
    if (isServer) {
      // Server-side code
      requestUrl = requestUrl.replace(publicRuntimeConfig.API_URL, publicRuntimeConfig.API_SERVER_URL);
    }
  }
  if (query) {
    requestUrl += `?${queryString.stringify(query, { arrayFormat: 'bracket' })}`;
  }

  const contentType = !formData ? { 'Content-Type': 'application/json' } : {};
  // for SSR we need to send header Cookie, for Client Side it will be automatically send with credentials: 'include'
  const authentication = token.length
    ? {
        Cookie: [
          `token=${token}`,
          `${current_admin_token.length ? `current_admin_token=${current_admin_token}` : ''}`,
          `${impersonator_token.length ? `impersonator_token=${impersonator_token}` : ''}`
        ]
          .join(';')
          .replace(/;+/g, ';')
      }
    : {};
  let fetchOptions = {
    method,
    headers: Object.assign(authentication, contentType, headers, XCSRFHeader()),
    credentials: 'include'
  };
  if (signal) {
    fetchOptions.signal = signal;
  } else {
    let controller;
    if (!isServer) {
      controller = await new AbortController();
      if (controller) {
        globalController.push({ url: requestUrl, controller });
        fetchOptions.signal = controller.signal;
      }
    }
  }
  if (method !== 'GET') {
    if (formData) {
      fetchOptions = {
        ...fetchOptions,
        body: formDataBody,
        processData: false,
        contentType: false,
        mimeType: 'multipart/form-data'
      };
    } else {
      let search = body?.contacts_scope?.search;
      try {
        if (body?.contacts_scope?.search) {
          body.contacts_scope.search = body.contacts_scope.search.filter(
            el => !(el.value === '' || (Array.isArray(el.value) && el.value.length === 0))
          );
        }
      } catch (error) {
        if (body?.contacts_scope?.search) body.contacts_scope.search = search;
      }
      fetchOptions = {
        ...fetchOptions,
        body: JSON.stringify(body)
      };
    }
  }

  let response;
  let jsonResponse;
  try {
    response = await fetch(requestUrl, fetchOptions);

    if (response?.status === 204) return;
    jsonResponse = await response.json();

    if (!response.ok) {
      const error = new Error('Request failed');
      error.jsonResponse = jsonResponse;
      error.response = response;
      throw error;
    }
    if (fullResponse) {
      return { response, jsonResponse };
    }

    return jsonResponse;
  } catch (err) {
    if (response && response.ok) {
      return {};
    }
    if (err.name == 'AbortError') {
      console.log('ABORT ERROR:', requestUrl);
      // throw err;
    }

    await handleError({ serverSide: isServer, response });
    if (response?.status === 403) {
      const error = new Error('Request failed');
      error.jsonResponse = { errors: { user: ['Access denied!'] } };
      error.response = response;
      throw error;
    }
    const error = new Error('Request failed');
    error.jsonResponse = jsonResponse;
    error.response = response;
    throw error;
  }
}
