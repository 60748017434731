import request from '@/utils/request';

export const API_TRACKING_VENDORS = 'API_TRACKING_VENDORS';
export const API_TRACKING_VENDOR = 'API_TRACKING_VENDOR';
export const API_TRACKING_KEYS = 'API_TRACKING_KEYS';
export const API_TRACKING_UPDATE_KEY = 'API_TRACKING_UPDATE_KEY';

export const fetchApiTrackingVendors = query => async dispatch => {
  const response = await request({
    path: `/v1/admin/api_tracking/api_vendors`,
    query
  });
  dispatch({
    payload: response,
    type: API_TRACKING_VENDORS
  });
};

export const fetchApiTrackingKeys = query => async dispatch => {
  const response = await request({
    path: `/v1/admin/api_tracking/api_keys`,
    query
  });
  dispatch({
    payload: response,
    type: API_TRACKING_KEYS
  });
};

export const updateApiTrackingKey = body => async dispatch => {
  const response = await request({
    path: `/v1/admin/api_tracking/api_keys/${body.id}`,
    method: 'PATCH',
    body
  });
  dispatch({
    payload: response,
    type: API_TRACKING_UPDATE_KEY
  });
};

export const fetchApiTrackingVendor =
  ({ id }) =>
  async dispatch => {
    const response = await request({
      path: `/v1/admin/api_tracking/api_vendors/${id}`
    });
    dispatch({
      payload: response,
      type: API_TRACKING_VENDOR
    });
  };

export const createApiTrackingVendor = body => async dispatch => {
  await request({
    path: `/v1/admin/api_tracking/api_vendors`,
    method: 'POST',
    body
  });
  dispatch({
    payload: { data: {} },
    type: API_TRACKING_VENDOR
  });
};

export const updateApiTrackingVendor = (id, body) => async dispatch => {
  await request({
    path: `/v1/admin/api_tracking/api_vendors/${id}`,
    method: 'PATCH',
    body
  });
  dispatch({
    payload: { data: {} },
    type: API_TRACKING_VENDOR
  });
};

export const deleteApiTrackingVendor = id => async () =>
  await request({
    path: `/v1/admin/api_tracking/api_vendors/${id}`,
    method: 'DELETE'
  });
