import React, { Component } from 'react';
import { withErrorBoundary } from '@/utils/errors';
import { connect } from 'react-redux';
import { publish } from '@/utils/publish';
import getConfig from 'next/config';

import { setStateAsync } from '@/utils/helpers';
import { setItem, getItem, setSessionItem, getSessionItem, removeItem } from '@/utils/storage';
import {
  RECONNECT_DELAY,
  OPEN_NEW_TAB_DELAY,
  WEBSOCKET_C1_MESSAGES,
  WEBSOCKET_SMS_MESSAGES,
  WEBSOCKET_CONTACTS_IMPORT
} from '@/store/models/websocket';
import { updateProcessingImportAttempts } from '@/store/actions/contacts.actions';

const { publicRuntimeConfig } = getConfig();

class WebSocketConnection extends Component {
  webSocket = React.createRef();
  state = {
    status: '',
    sent: false,
    websocketUrl: ''
  };

  setStateAsync = setStateAsync.bind(this);

  async componentDidMount() {
    if (this.props.allowConnection) {
      await this.setStateAsync({
        websocketUrl: publicRuntimeConfig.API_URL?.includes('https')
          ? `${publicRuntimeConfig.API_URL?.replace('https', 'wss')}/cable`
          : `${publicRuntimeConfig.API_URL?.replace('http', 'ws')}/cable`
      });
      await this.setupConnection();
    }
  }

  setupConnection = () => {
    this.webSocket.current = this.openConnection();
    try {
      this.setupHandlers();
    } catch (error) {
      console.error(error);
    }
  };

  setupHandlers = () => {
    this.webSocket.current.onopen = () => {
      try {
        this.props.currentUser.c_1 && this.webSocket.current.send(JSON.stringify(WEBSOCKET_C1_MESSAGES.subscribe));
        this.webSocket.current.send(JSON.stringify(WEBSOCKET_SMS_MESSAGES.subscribe));
        // this.webSocket.current.send(JSON.stringify(WEBSOCKET_CONTACTS_IMPORT.subscribe));
      } catch (error) {
        console.error(error);
      }
    };
    this.webSocket.current.onmessage = async e => {
      if (!this.state.sent && this.state.status == 'CONNECTED') {
        console.log('Message sent ...');
        this.props.currentUser.c_1 &&
          this.webSocket.current.send(JSON.stringify(WEBSOCKET_C1_MESSAGES.validate_stream));
        this.webSocket.current.send(JSON.stringify(WEBSOCKET_SMS_MESSAGES.validate_stream));
        // this.webSocket.current.send(JSON.stringify(WEBSOCKET_CONTACTS_IMPORT.validate_stream));
        this.setState({ sent: true });
      }
      if (!this.skipMessage(e.data)) {
        try {
          let payload = JSON.parse(e.data);
          let identifier = JSON.parse(payload.identifier);
          if (identifier.channel === 'SmsChannel') {
            if (payload?.message && payload.message.phone_number) {
              await setSessionItem('incomingSms', true);
              setTimeout(() => {
                if (getSessionItem('incomingSms')) this.notifyUser(payload.message.phone_number);
              }, OPEN_NEW_TAB_DELAY);
            }
          }
          if (identifier.channel === 'ConvergeOneChannel') {
            if (payload?.message && payload.message.phone_number) {
              await setSessionItem('incomingCall', true);
              setTimeout(() => {
                if (getSessionItem('incomingCall') && !getItem(`wsTabOpened-${payload.message.phone_number}`)) {
                  this.screenPop(payload.message.phone_number);
                  setItem(`wsTabOpened-${payload.message.phone_number}`, true);
                }
              }, OPEN_NEW_TAB_DELAY);
            }
          }
          if (identifier.channel === 'ContactsImportChannel') {
            if (payload.message.id) {
              this.props.updateProcessingImportAttempts(payload.message);
            }
          }
        } catch (error) {
          console.error(error);
        }
      }
    };
    this.webSocket.current.onerror = e => {
      console.log(`Websocket encountered error:${e}, closing connection`);
      this.webSocket.current?.close();
      this.setState({ status: 'CONNECTING' });
      setTimeout(() => this.setupConnection(), RECONNECT_DELAY);
    };
  };

  skipMessage = data => {
    let parsedData = JSON.parse(data);
    let skip = 0;
    switch (parsedData.type) {
      case 'welcome':
        this.setState({ status: 'CONNECTING' });
        skip = 1;
        console.log('Websocket Connecting ...');
        break;
      case 'confirm_subscription':
        this.setState({ status: 'CONNECTED' });
        skip = 1;
        console.log('Websocket Connected ...');
        break;
      case 'ping':
        skip = 1;
        break;
      case 'disconnect':
        console.log('Websocket Reconnecting ...');
        this.webSocket.current?.close();
        this.setState({ status: 'CONNECTING' });
        setTimeout(() => this.setupConnection(), RECONNECT_DELAY);
        skip = 1;
        break;
    }
    return skip;
  };

  notifyUser = async phone_number => {
    await removeItem('incomingSms');
    publish('success', `New message from ${phone_number}`);
  };

  screenPop = async phone_number => {
    await removeItem('incomingCall');
    setItem('category', '');
    let group = 'all_agents';
    if (this.props.currentUser.admin) group = 'all';
    setItem('contact_group', group);
    setItem('search', phone_number);
    setItem('incomingCallMsg', true);
    window.open('/contacts', '_blank');
  };

  openConnection = () => {
    return new WebSocket(this.state.websocketUrl);
  };

  componentWillUnmount() {
    this.webSocket.current?.close();
  }

  render() {
    return null;
  }
}

const mapStateToProps = state => ({
  currentUser: state.currentUser
});

export default connect(mapStateToProps, { updateProcessingImportAttempts }, null, { forwardRef: true })(
  withErrorBoundary(WebSocketConnection)
);
