import request from '@/utils/request';

import { getSessionItem } from '@/utils/storage';

export const STORE_ITEMS = 'STORE_ITEMS';
export const STORE_ITEM = 'STORE_ITEM';

export const fetchStoreItems = () => async dispatch => {
  const response = await request({
    path: `/v1/store_items`
  });
  dispatch({
    payload: response,
    type: STORE_ITEMS
  });
};

export const fetchStoreItem = id => async dispatch => {
  const response = await request({
    path: `/v1/store_items/${id}`
  });
  dispatch({
    payload: response,
    type: STORE_ITEM
  });
};

export const createStoreOrder = body => async () => {
  const response = await request({
    path: `/v1/store_orders`,
    method: 'POST',
    body: { ...body, contacts_scope: JSON.parse(getSessionItem('fetchContactsParams')) }
  });
  return response.data;
};

export const fetchStoreOrder = () => async () => {
  const response = await request({
    path: `/v1/store_orders/agent_info`
  });
  return response.data;
};
