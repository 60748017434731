import { createStore, applyMiddleware } from 'redux';
import { thunk } from 'redux-thunk';
import logger from 'redux-logger';
import rootReducer from './reducers/reducers';

import getConfig from 'next/config';
const { publicRuntimeConfig } = getConfig();

const bindMiddleware = middleware => {
  if (process.env.NODE_ENV !== 'production') {
    // I require this only in dev environment
    // eslint-disable-next-line no-undef
    const { composeWithDevToolsDevelopmentOnly } = require('@redux-devtools/extension');
    return composeWithDevToolsDevelopmentOnly(applyMiddleware(...middleware));
  }
  return applyMiddleware(...middleware);
};

export const initStore = (initialState = {}) => {
  let middleware = [thunk];
  if (publicRuntimeConfig.ENABLE_CONSOLE_LOGS === 'true') {
    middleware = [...middleware, logger];
  }
  return createStore(rootReducer, initialState, bindMiddleware(middleware));
};
