export const FONT_SIZE_SMALL = 'font-size-small';
export const FONT_SIZE_DEFAULT = 'font-size-default';
export const FONT_SIZE_LARGE = 'font-size-large';

export const PRIMARY_COLOR_ORANGE = 'primary-color-orange';
export const PRIMARY_COLOR_GREEN = 'primary-color-green';
export const PRIMARY_COLOR_PINK = 'primary-color-pink';
export const PRIMARY_COLOR_YELLOW = 'primary-color-yellow';
export const PRIMARY_COLOR_PURPLE = 'primary-color-purple';
export const PRIMARY_COLOR_ORANGE_HEX = '#ff4113';
export const PRIMARY_COLOR_GREEN_HEX = '#00f4bd';
export const PRIMARY_COLOR_PINK_HEX = '#ff006f';
export const PRIMARY_COLOR_YELLOW_HEX = '#ffb900';
export const PRIMARY_COLOR_PURPLE_HEX = '#5300ff';

export const BACKGROUND_DEFAULT = 'background-default';
export const BACKGROUND_DARK = 'background-dark';
export const FONT_SIZE = 'FONT_SIZE';
export const PRIMARY_COLOR = 'PRIMARY_COLOR';
export const BACKGROUND = 'BACKGROUND';
