import {
  API_TRACKING_VENDORS,
  API_TRACKING_VENDOR,
  API_TRACKING_KEYS,
  API_TRACKING_UPDATE_KEY
} from '@/store/actions/admin/api_tracking.actions';

const initialState = {
  api_tracking_vendors: [],
  api_tracking_vendors_meta: {},
  api_tracking_vendor: {},
  api_tracking_keys: [],
  api_tracking_keys_meta: {}
};

export default function users(state = initialState, action) {
  switch (action.type) {
    case API_TRACKING_VENDORS:
      return {
        ...state,
        api_tracking_vendors: action.payload.data,
        api_tracking_vendors_meta: action.payload.meta
      };
    case API_TRACKING_VENDOR:
      return {
        ...state,
        api_tracking_vendor: action.payload.data
      };
    case API_TRACKING_KEYS:
      return {
        ...state,
        api_tracking_keys: action.payload.data,
        api_tracking_keys_meta: action.payload.meta
      };
    case API_TRACKING_UPDATE_KEY:
      return {
        ...state,
        api_tracking_keys: state.api_tracking_keys.map(el =>
          el.id === action.payload.data.id ? action.payload.data : el
        )
      };
    default:
      return state;
  }
}
