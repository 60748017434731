import React, { Component } from 'react';
import { withErrorBoundary } from '@/utils/errors';
import classNames from 'classnames';
import styles from './DialerInbound.module.scss';

class DialerInbound extends Component {
  render() {
    return (
      <>
        <div className={classNames(styles.DialerInboundWrapper)}>
          <div className={classNames(styles.DialerInboundIconContainer)}>
            <div className={classNames(styles.DialerInboundIcon)}>
              <i className="fa-solid fa-phone"></i>
            </div>
          </div>

          <div className={classNames(styles.DialerInboundInfoContainer)}>
            <div className={classNames(styles.DialerInboundInfo)}>
              {this.props?.associated_contact && this.props?.associated_contact?.first_name ? (
                <>
                  <h2 className={classNames(styles.DialerInboundInfoTitle)}>
                    {this.props?.associated_contact?.first_name + ' ' + this.props?.associated_contact?.last_name}
                  </h2>
                  <p className={classNames(styles.DialerInboundInfoText)}>{this.props.phoneNumber}</p>
                </>
              ) : (
                <>
                  <h2 className={classNames(styles.DialerInboundInfoTitle)}>{this.props?.phoneNumber}</h2>
                </>
              )}
              <p className={classNames(styles.DialerInboundInfoSubText)}>Incoming Call ...</p>
            </div>
          </div>

          <div className={classNames(styles.DialerInboundActions)}>
            <div className={classNames(styles.DialerInboundActionItem)}>
              <div
                className={classNames(styles.DialerInboundActionItemButton, styles.Reject)}
                onClick={() => this.props.handleRejectCall()}
              >
                <i className={classNames('fa-solid', 'fa-phone', 'fa-rotate-by', styles.Rotate135Deg)}></i>
              </div>
            </div>
            <div className={classNames(styles.DialerInboundActionItem)}>
              <div
                className={classNames(styles.DialerInboundActionItemButton, styles.Accept)}
                onClick={() => this.props.handleAnswerCall()}
              >
                <i className={classNames('fa-solid', 'fa-phone', 'fa-rotate-by')}></i>
              </div>
            </div>
          </div>

          <div className={classNames(styles.DialerInboundMatches)}>
            {/* {this.props?.associated_contact.length && (
                    <div className={classNames(styles.DialerInboundMatchesTitle)}>
                      <h3>Matching Contacts</h3>
                    </div>
                  )}
                  {this.props?.associated_contact.length && (
                      this.props?.associated_contact.map((val, key) => {
                        return (
                          <>
                            <div key={key} className={classNames(styles.DialerInboundMatchesRow)}>
                              <div className={classNames(styles.DialerInboundMatchesRowInfo)}>
                                <div className={classNames(styles.DialerInboundMatchesRowName)}>
                                  {val.first_name} {val.last_name}
                                </div>
                                <div className={classNames(styles.DialerInboundMatchesRowAddress)}>
                                  {val.city} {val.state}
                                </div>
                              </div>
                              <div className={classNames(styles.DialerInboundMatchesRowActions)}>
                                <div className={classNames(styles.DialerInboundMatchesRowAction)}>
                                  <button className={classNames(styles.DialerInboundMatchesRowActionButton)}>
                                    <i className={classNames('fa-solid', 'fa-plus')}></i>
                                  </button>
                                </div>
                              </div>
                            </div>
                          </>
                        )
                      })

                  )} */}
          </div>
        </div>
      </>
    );
  }
}

export default withErrorBoundary(DialerInbound);
