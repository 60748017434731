import request from '@/utils/request';

export const TRAININGS = 'TRAININGS';
export const TRAINING = 'TRAINING';

export const fetchTrainings = query => async dispatch => {
  const response = await request({
    path: `/v1/admin/trainings`,
    query
  });
  dispatch({
    payload: response,
    type: TRAININGS
  });
};

export const fetchTraining = id => async dispatch => {
  const response = await request({
    path: `/v1/admin/trainings/${id}`
  });
  dispatch({
    payload: response,
    type: TRAINING
  });
};

export const createTraining = data => async () => {
  const formDataBody = new FormData();
  formDataBody.append('section', data.section);
  formDataBody.append('title', data.title);
  formDataBody.append('file', data.file);
  formDataBody.append('corporation_id', data.corporation_id);
  data.for_account_types.length && formDataBody.append('for_account_types', data.for_account_types);
  await request({
    path: `/v1/admin/trainings`,
    method: 'POST',
    formData: true,
    formDataBody
  });
};

export const updateTraining = data => async () => {
  const formDataBody = new FormData();
  formDataBody.append('section', data.section);
  formDataBody.append('title', data.title);
  formDataBody.append('corporation_id', data.corporation_id);
  data.for_account_types.length && formDataBody.append('for_account_types', data.for_account_types);
  data.file && formDataBody.append('file', data.file);

  await request({
    path: `/v1/admin/trainings/${data.id}`,
    method: 'PATCH',
    formData: true,
    formDataBody
  });
};

export const deleteTraining = id => async () =>
  await request({
    path: `/v1/admin/trainings/${id}`,
    method: 'DELETE'
  });

export const deleteTrainingSection = (id, body) => async () =>
  await request({
    path: `/v1/admin/trainings/${id}/delete_section`,
    method: 'DELETE',
    body
  });
