import { SET_LOADING, SET_LOADING_ROUTER } from '@/store/actions/session.actions';

const initialState = {
  loading: true,
  loading_router: false
};

export default function session(state = initialState, action) {
  switch (action.type) {
    case SET_LOADING:
      return {
        ...state,
        loading: action.payload
      };
    case SET_LOADING_ROUTER:
      return {
        ...state,
        loading_router: action.payload
      };
    default:
      return state;
  }
}
