import request from '@/utils/request';
import Router from 'next/router';

import { removeSessionItem, setSessionItem } from '@/utils/storage';

export const LOG_OUT = 'LOG_OUT';
export const SET_LOADING = 'SET_LOADING';
export const SET_LOADING_ROUTER = 'SET_LOADING_ROUTER';
export const LOCAL_STORAGE_DUPLICATE_KEY = 'advanced_search_duplicate';
export const LOCAL_STORAGE_SEARCH_KEY = 'advanced_search_form';

export const setLoading = type => async dispatch =>
  dispatch({
    payload: type,
    type: SET_LOADING
  });

export const setLoadingRouter = type => async dispatch =>
  dispatch({
    payload: type,
    type: SET_LOADING_ROUTER
  });

export const loginUser = data => async dispatch => {
  let _SESSION = await request({
    path: `/v1/sessions`,
    method: 'POST',
    body: data
  });
  dispatch(clearAdminStorage());
  if (_SESSION && _SESSION.otp_secret && _SESSION.last_otp_at) {
    return _SESSION;
  } else {
    window.location = '/';
  }
};

export const loginSsoUser = data => async dispatch => {
  const _SESSION = await request({
    path: `/v1/sessions/sso`,
    method: 'POST',
    body: data
  });
  dispatch(clearAdminStorage());
  if (_SESSION && _SESSION.otp_secret && _SESSION.last_otp_at) {
    return _SESSION;
  } else {
    window.location = '/';
  }
};

export const clearSearchStorage = () => async () => {
  await removeSessionItem('search');
  await removeSessionItem('searches');
  await removeSessionItem(LOCAL_STORAGE_SEARCH_KEY);
  await removeSessionItem(LOCAL_STORAGE_DUPLICATE_KEY);
  await removeSessionItem('advancedSearchActiveOptions');
  await removeSessionItem('carriers');
  await removeSessionItem('carriersActiveObj');
  await removeSessionItem('exclude_policy_types');
  await removeSessionItem('include_policy_types');
  await removeSessionItem('include_policy_typesActiveObj');
  await removeSessionItem('exclude_policy_typesActiveObj');
  await removeSessionItem('include_tags');
  await removeSessionItem('exclude_tags');
  await removeSessionItem('include_tagsActiveObj');
  await removeSessionItem('exclude_tagsActiveObj');
  await removeSessionItem('addressActiveDuplicate');
  await removeSessionItem('first_nameActiveDuplicate');
  await removeSessionItem('last_nameActiveDuplicate');
  await removeSessionItem('fetchContactsParams');
  await removeSessionItem('category');
  await removeSessionItem('contact_group');
  await removeSessionItem('all_downline');
  await removeSessionItem('order_field');
};

export const clearAdminStorage =
  ({ persistLoginStack = false } = {}) =>
  async () => {
    if (!persistLoginStack) localStorage.removeItem('loginStack');
  };

export const onLogOut =
  ({ persistLoginStack = false } = {}) =>
  async dispatch => {
    dispatch(clearSearchStorage());
    dispatch(clearAdminStorage({ persistLoginStack }), { type: LOG_OUT });
    dispatch({ type: LOG_OUT });
  };

export const logOut = () => async dispatch => {
  try {
    setSessionItem('isMiniCalendarVisible', false);
    await setLoading(true);
    await dispatch(onLogOut());
    await request({
      path: `/v1/sessions`,
      method: 'DELETE'
    });
    Router.push('/login');
    window.location.replace('/login');
  } catch (err) {
    await setLoading(false);
    throw err;
  }
};

export const resetPassword = body => () =>
  request({
    path: `/v1/users/reset_password_instructions`,
    method: 'POST',
    body
  });

export const resetPasswordByToken = body => () =>
  request({
    path: `/v1/users/reset_password_by_token`,
    method: 'POST',
    body
  });
