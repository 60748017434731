import request from '@/utils/request';
import getConfig from 'next/config';
import { saveAs } from 'file-saver';
import queryString from 'query-string';

import { XCSRFHeader } from '@/utils/auth';

const { publicRuntimeConfig } = getConfig();

export const REPORT = 'REPORT';
export const REPORT_XLSX = 'REPORT_XLSX';
export const SAVE_REPORT_DATA = 'SAVE_REPORT_DATA';
export const SET_SELECTED_REPORT = 'SET_SELECTED_REPORT';
export const REPORTS = 'REPORTS';
export const REPORTS_TYPES = 'REPORTS_TYPES';

export const fetchReports = (limit, page, filter_by, filter_value) => async dispatch => {
  await request({
    path: `/v1/reports`,
    query: { limit, page, filter_by, filter_value }
  }).then(response => {
    dispatch({
      payload: response,
      type: REPORTS
    });
  });
};

export const fetchReportsTypes = () => async dispatch => {
  const response = await request({
    path: `/v1/reports/types`
  });
  dispatch({
    payload: response,
    type: REPORTS_TYPES
  });
};

export const fetchReportsCreatedBy = query => async () =>
  await request({
    path: `/v1/reports/created_by`,
    query
  });

export const createReport = body => async () =>
  await request({
    path: `/v1/reports`,
    method: 'POST',
    body
  });

export const fetchReport = (reportId, body) => async () =>
  await request({
    path: `/v1/reports/${reportId}`,
    method: 'POST',
    body
  });

export const exportReportXLSX = data => async dispatch => {
  let url = `${publicRuntimeConfig.API_URL}/v1/reports/export.xlsx`;
  url += `?${queryString.stringify(data, { arrayFormat: 'bracket' })}`;
  await fetch(url, {
    headers: Object.assign({ 'Content-Type': 'application/json' }, XCSRFHeader()),
    credentials: 'include',
    responseType: 'blob'
  }).then(response =>
    dispatch({
      payload: response.blob().then(blob => saveAs(blob, `${data.report_type}_report.xlsx`)),
      type: REPORT_XLSX
    })
  );
};

export const saveReportData = data => async dispatch =>
  dispatch({
    payload: data,
    type: SAVE_REPORT_DATA
  });

export const deleteReport = reportId => async () =>
  await request({
    path: `/v1/reports/${reportId}`,
    method: 'DELETE'
  });

export const setSelectedReport = data => dispatch => dispatch({ type: SET_SELECTED_REPORT, payload: data });
