import { combineReducers } from 'redux';
import sessionReducer from './session.reducer';
import currentUserReducer from './currentUser.reducer';
import currentAccountReducer from './currentAccount.reducer';
import storeReducer from './store.reducer';
import eventReducer from './event.reducer';
import contactsReducer from './contacts.reducer';
import reportReducer from './report.reducer';
import authorizationReducer from './authorization.reducer';
import publicReducer from './public.reducer';
import dialerReducer from './dialer.reducer';
import otherReducer from './other.reducer';
import smsReducer from './sms.reducer';
import adminUsersReducer from './admin/users.reducer';
import adminTrainingsReducer from './admin/trainings.reducer';
import adminSettingsReducer from './admin/settings.reducer';
import adminTransfersReducer from './admin/transfers.reducer';
import adminSmsReducer from './admin/sms.reducer';
import adminDialerReducer from './admin/dialer.reducer';
import adminApiTrackingReducer from './admin/api_tracking.reducer';
import { LOG_OUT } from '../actions/session.actions';

const appReducer = combineReducers({
  session: sessionReducer,
  currentUser: currentUserReducer,
  currentAccount: currentAccountReducer,
  store: storeReducer,
  event: eventReducer,
  contacts: contactsReducer,
  report: reportReducer,
  authorization: authorizationReducer,
  dialer: dialerReducer,
  public: publicReducer,
  other: otherReducer,
  sms: smsReducer,
  admin: combineReducers({
    users: adminUsersReducer,
    trainings: adminTrainingsReducer,
    settings: adminSettingsReducer,
    transfers: adminTransfersReducer,
    dialer: adminDialerReducer,
    sms: adminSmsReducer,
    api_tracking: adminApiTrackingReducer
  })
});

const rootReducer = (state, action) => {
  if (action.type === LOG_OUT) {
    return appReducer(undefined, action);
  }

  return appReducer(state, action);
};

export default rootReducer;
